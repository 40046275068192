<script setup lang="ts">
import { getJobTitle } from '@/core/sourcing/utils/job';
import type { JobApplicant } from '@factoryfixinc/ats-interfaces';

import { useRouter } from 'vue-router';

const router = useRouter();

const DEFAULT_ITEMS = 3;
const MAX_ITEMS = 10;

const props = defineProps<{
  jobApplicants: JobApplicant[];
  isExpanded: boolean;
}>();

const isOutreach = (jobApplicant: JobApplicant) => {
  return jobApplicant.initiatedByOutreach;
};

const getChipClass = (jobApplicant: JobApplicant) => {
  return isOutreach(jobApplicant) ? 'bg-highlight-50' : 'bg-primary-50';
};

const getTextClass = (proJobApplicant: JobApplicant) => {
  return isOutreach(proJobApplicant) ? 'text-highlight-600' : 'text-primary-500';
};

const getActionText = (proJobApplicant: JobApplicant) => {
  return isOutreach(proJobApplicant) ? 'Added to' : 'Applied to';
};

const navigateToConversations = (jobApplicant: JobApplicant) => {
  router.push({
    name: 'resume-tab',
    query: {
      isDeepLink: 1,
      jobApplicantId: jobApplicant.id,
      ...(jobApplicant.job?.id && { jobId: jobApplicant.job.id }),
    },
  });
};

// If the list is expanded, navigate to the 11th applicant from the Chip Counter
// otherwise do nothing so the user clicks and expands the ProPreviewCard
const navigateToConversationsFromChipCounter = () => {
  if (props.isExpanded) {
    navigateToConversations(props.jobApplicants[MAX_ITEMS]);
  }
};

const getVisibleApplicants = (applicants: JobApplicant[], expanded: boolean) => {
  if (!expanded) {
    return applicants.slice(0, DEFAULT_ITEMS);
  }
  return applicants.slice(0, 10);
};

const getRemainingCount = (applicants: JobApplicant[], expanded: boolean) => {
  if (!expanded) {
    return applicants.length <= DEFAULT_ITEMS ? 0 : applicants.length - DEFAULT_ITEMS;
  }
  return applicants.length <= MAX_ITEMS ? 0 : applicants.length - MAX_ITEMS;
};

// Candidates from Copilot Outreach do not have an applyTs until they are moved to New, Engaged, etc.
// this will prevent the date with 'Invalid Date' text from showing up in the UI until the candidate is moved to a different status
const getApplyDate = (applyDate: string | undefined) => {
  return applyDate ? ` on ${new Date(applyDate).toLocaleDateString()}` : '';
};
</script>

<template>
  <div class="chips">
    <span
      class="chip cursor-pointer"
      :class="getChipClass(proJobApplicant)"
      v-for="proJobApplicant in getVisibleApplicants(jobApplicants, isExpanded)"
      :key="proJobApplicant.id"
      @click.stop="navigateToConversations(proJobApplicant)"
    >
      {{ getActionText(proJobApplicant) }}
      {{ getJobTitle(proJobApplicant.job).displayTitle }} (#{{ proJobApplicant.job?.id }})
      {{ getApplyDate(proJobApplicant.applyTs) }}
      <span class="font-bold" :class="getTextClass(proJobApplicant)">Review application</span>
    </span>
    <span
      v-if="getRemainingCount(jobApplicants, isExpanded) > 0"
      class="chip-counter"
      @click="navigateToConversationsFromChipCounter()"
    >
      + {{ getRemainingCount(jobApplicants, isExpanded) }} more
    </span>
  </div>
</template>

<style scoped lang="postcss">
.chip {
  @apply mb-1 mr-0.5 inline-block items-center justify-center gap-1;
  @apply rounded-full px-2 py-0.5 text-xs leading-[18px] text-shade-900;

  &:hover {
    @apply opacity-75;
  }
}

.chip-counter {
  @apply inline-block items-center justify-center gap-1;
  @apply rounded-[50px] px-2 py-0.5 text-xs font-bold leading-[18px];
  @apply bg-tint-60 text-shade-840;
}
</style>
