import { useAtsProjectsApi, useAtsProjectsApiWithOptions } from '@/composables/useApi';
import SearchUtils from '@/utils/search-utils';

import type {
  CreateProjectInput,
  DuplicateProjectInput,
  GetEmployerLocationsInput,
  GetEmployerLocationsOutput,
  SearchProjectsInput,
  SearchProjectsOutput,
  UpdateProjectInput,
} from './project.type';
import type { Project, ProjectWithRelations } from '@factoryfixinc/ats-interfaces';
import { useMeStore } from '@/core/shared/me/me.store';
import { InternalError } from '@/core/shared/errors/internal.error';

export class ProjectPersistence {
  private readonly meStore = useMeStore();

  public async searchProjects(params: SearchProjectsInput): Promise<SearchProjectsOutput> {
    const baseUrl = `/project/employer/${params.employerId}`;
    const search = SearchUtils.convertSearchObjectToSearchUrl(params.search || {});
    const url = `${baseUrl}?${search}`;
    const { data, error, statusCode } = await useAtsProjectsApi(url)
      .get()
      .json<SearchProjectsOutput>();

    if (error.value) {
      throw new InternalError('Could not search projects', {
        status: statusCode.value,
        error: error.value,
        data: params,
      });
    }

    return data.value ?? { projects: [], total: 0 };
  }

  public async getProjectById(
    employerId: number,
    projectId: number,
  ): Promise<ProjectWithRelations<'candidates'> | undefined> {
    const url = `/project/${projectId}/employer/${employerId}`;
    const { data, error, statusCode } = await useAtsProjectsApi(url)
      .get()
      .json<ProjectWithRelations<'candidates'>>();

    if (statusCode.value === 404) {
      return undefined;
    }

    if (error.value) {
      throw new InternalError('Could not get project by id', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId },
      });
    }

    return data.value ?? undefined;
  }

  public async getEmployerLocations(
    params: GetEmployerLocationsInput,
  ): Promise<GetEmployerLocationsOutput> {
    const url = `/project/employer/${params.employerId}/locations`;
    const { data, error, statusCode } =
      await useAtsProjectsApi(url).json<GetEmployerLocationsOutput>();

    if (error.value) {
      throw new InternalError('Could not get employer locations', {
        error: error.value,
        status: statusCode.value,
        data: params,
      });
    }

    return data.value ?? { locations: [] };
  }

  public async addWatcher(
    employerId: number,
    projectId: number,
    userProfileId: number,
  ): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/watcher/${userProfileId}`;
    const { error, statusCode } = await useAtsProjectsApi(url).post();

    if (error.value) {
      throw new InternalError('Could not add watcher', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, userProfileId },
      });
    }
  }

  public async removeWatcher(
    employerId: number,
    projectId: number,
    userProfileId: number,
  ): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/watcher/${userProfileId}`;
    const { error, statusCode } = await useAtsProjectsApi(url).delete();

    if (error.value) {
      throw new InternalError('Could not remove watcher', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, userProfileId },
      });
    }
  }

  public async updateAssignee(
    employerId: number,
    projectId: number,
    userProfileId: number,
  ): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/update-project-assignee`;
    const { error, statusCode } = await useAtsProjectsApi(url).patch({
      assigneeId: userProfileId,
    });

    if (error.value) {
      throw new InternalError('Could not update assignee', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, userProfileId },
      });
    }
  }

  public async archiveProject(employerId: number, projectId: number): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/archive-project`;
    const { error, statusCode } = await useAtsProjectsApi(url).patch();

    if (error.value) {
      throw new InternalError('Could not archive project', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId },
      });
    }
  }

  public async activateProject(employerId: number, projectId: number): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/activate-project`;
    const { error, statusCode } = await useAtsProjectsApi(url).patch();

    if (error.value) {
      throw new InternalError('Could not activate project', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId },
      });
    }
  }

  public async deleteProject(employerId: number, projectId: number): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}`;
    const { error, statusCode } = await useAtsProjectsApi(url).delete();

    if (error.value) {
      throw new InternalError('Could not delete project', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId },
      });
    }
  }

  public async updateProject(
    employerId: number,
    projectId: number,
    data: UpdateProjectInput,
  ): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/update-project-properties`;
    const { error, statusCode } = await useAtsProjectsApi(url).patch(data);

    if (error.value) {
      throw new InternalError('Could not update project', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, data },
      });
    }
  }

  public async duplicateProject(
    employerId: number,
    projectId: number,
    duplicateProjectInput: DuplicateProjectInput,
  ): Promise<Project> {
    const url = `/project/${projectId}/employer/${employerId}/duplicate-project`;
    const { error, data, statusCode } = await useAtsProjectsApi(url)
      .post(duplicateProjectInput)
      .json<Project>();

    if (error.value) {
      throw new InternalError('Could not duplicate project', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, duplicateProjectInput },
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response received from duplicateProject', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, duplicateProjectInput },
      });
    }

    return data.value;
  }

  public async createProject(
    employerId: number,
    createProjectInput: CreateProjectInput,
  ): Promise<Project> {
    const url = `/project/employer/${employerId}`;
    const { error, data, statusCode } = await useAtsProjectsApi(url)
      .post(createProjectInput)
      .json<Project>();

    if (error.value) {
      throw new InternalError('Could not create project', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, createProjectInput },
      });
    }

    if (!data.value) {
      throw new InternalError('Empty response received from createProject', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, createProjectInput },
      });
    }

    return data.value;
  }

  public async updateCopilotStatus(
    employerId: number,
    projectId: number,
    enabled: boolean,
  ): Promise<void> {
    const url = `/project/${projectId}/employer/${employerId}/update-copilot-status`;
    const { error, statusCode } = await useAtsProjectsApi(url).patch({
      shouldActivate: enabled,
    });

    if (error.value) {
      throw new InternalError('Could not update copilot status', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, projectId, enabled },
      });
    }
  }

  public async setProjectSavedSearch<T>(projectId: number, savedSearches: T[]): Promise<void> {
    const employerId = this.meStore.employer?.id;
    const url = `/project/${projectId}/employer/${employerId}/saved-searches`;

    const { error, statusCode } = await useAtsProjectsApi(url).put({ savedSearches });

    if (error.value) {
      throw new InternalError('Could not set saved searches', {
        error: error.value,
        status: statusCode.value,
        data: { projectId, savedSearches },
      });
    }
  }

  public async getProjectByJobId(
    employerId: number,
    jobId: number,
  ): Promise<ProjectWithRelations<'candidates'> | undefined> {
    // Adding validation to prevent API calls with undefined jobId
    if (!employerId || !jobId) {
      return undefined;
    }

    const url = `/project/job/${jobId}/employer/${employerId}`;
    const { data, error, statusCode } = await useAtsProjectsApiWithOptions({ parseError: true })(
      url,
    )
      .get()
      .json<ProjectWithRelations<'candidates'>>();

    const isProjectRemoved = error.value?.includes('Project is removed');
    if (isProjectRemoved) {
      throw new InternalError('Project is removed', {
        error: JSON.parse(error.value),
        status: statusCode.value,
        data: { employerId, jobId },
      });
    }

    if (statusCode.value === 404) {
      return undefined;
    }

    if (error.value) {
      throw new InternalError('Could not get project by job id', {
        error: error.value,
        status: statusCode.value,
        data: { employerId, jobId },
      });
    }

    return data.value ?? undefined;
  }
}
