import {
  type Pagination,
  type Project,
  ProjectStatus,
  type ProjectWithRelations,
  type SearchProjects,
  type SearchProjectsOrderBy,
  type UserProfile,
} from '@factoryfixinc/ats-interfaces';

export type SearchProjectsInput = {
  employerId: number;
  search?: Partial<SearchProjects>;
};

export type GetEmployerLocationsInput = {
  employerId: number;
};

export type GetEmployerLocationsOutput = {
  locations: string[];
};

export type UpdateProjectInput = {
  title?: string;
  location?: string;
  generateQueryForSavedSearch?: boolean;
  scheduledUpdates?: boolean;
};

export type DuplicateProjectInput = {
  title?: string;
  location?: string;
};

export type CreateProjectInput = {
  title: string;
  location: string;
  activeOutreachEnabled?: boolean;
};

// This will be removed once Fernando updates ats-interfaces
export enum OrderByFields {
  UPDATE_TS = 'updateTs',
  TITLE = 'title',
}

export enum ProjectFilterTab {
  MY_JOBS = 'my_jobs',
  ALL_JOBS = 'all_jobs',
}

export interface SearchProjectsOutput {
  projects: ProjectWithRelations<'candidates'>[];
  total: number;
}

export {
  ProjectStatus,
  type Project,
  type SearchProjects,
  type ProjectWithRelations,
  type Pagination,
  type UserProfile,
  type SearchProjectsOrderBy,
};
