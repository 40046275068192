import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from 'vue-router';
import DefaultLayout from '@/layouts/default.vue';
import JobsLayout from '@/layouts/jobs.vue';
import SettingsLayout from '@/layouts/settings.vue';
import Candidates from '@/views/candidates/index.vue';
import PerformanceDashboard from '@/views/performance-dashboard/index.vue';
import Jobs from '@/views/jobs/jobs.vue';
import JobsCopilotActivation from '@/views/jobs/copilot-activation.vue';
import Conversations from '@/views/conversations/conversations.vue';
import ConversationsTabConversationTab from '@/views/conversations/tabs/conversation-tab.vue';
import ConversationsTabApplicationsTab from '@/views/conversations/tabs/applications-tab.vue';
import ConversationsTabResumeTab from '@/views/conversations/tabs/resume-tab.vue';
import ConversationsTabNotesTab from '@/views/conversations/tabs/notes-tab.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import SearchUtils from '@/utils/search-utils';
import MeService from '@/core/shared/me/me.service';
import SearchIndex from '@/views/search/index.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'index',
      component: DefaultLayout,
      children: [
        { path: '/', redirect: '/conversations' },
        {
          path: '/candidates',
          name: 'candidates',
          component: Candidates,
        },
        {
          path: '/performance-dashboard',
          name: 'performance-dashboard',
          component: PerformanceDashboard,
        },
        {
          path: '/',
          component: JobsLayout,
          children: [
            {
              path: '/sourcing',
              name: 'sourcing',
              children: [
                {
                  path: '',
                  name: 'sourcing-index',
                  redirect: '/search',
                },
                {
                  path: '/sourcing/job/:id',
                  name: 'sourcing-job-id',
                  redirect: '/search',
                },
              ],
            },
            {
              path: 'conversations',
              name: 'conversations',
              component: Conversations,
              redirect: { name: 'resume-tab' },
              children: [
                {
                  path: 'conversation',
                  name: 'conversation-tab',
                  component: ConversationsTabConversationTab,
                },
                {
                  path: 'applications',
                  name: 'applications-tab',
                  component: ConversationsTabApplicationsTab,
                },
                {
                  path: 'resume',
                  name: 'resume-tab',
                  component: ConversationsTabResumeTab,
                },
                {
                  path: 'notes',
                  name: 'notes-tab',
                  component: ConversationsTabNotesTab,
                },
              ],
            },
          ],
        },
        {
          path: '/settings',
          component: SettingsLayout,
          redirect: '/settings/company',
          children: [
            {
              path: 'company',
              name: 'settings-company',
              component: () => import('@/views/settings/company/company-overview.vue'),
            },
            {
              path: 'members',
              name: 'settings-members',
              component: () => import('@/views/settings/company/members/members.vue'),
            },
            {
              path: 'members/:id',
              name: 'settings-member-id',
              component: () => import('@/views/settings/company/members/member-profile.vue'),
              beforeEnter: (to, from, next) => {
                const meService = new MeService();
                if (meService.isEmployerAdmin) {
                  next();
                } else {
                  next({ name: 'settings-members' });
                }
              },
            },
            {
              path: 'profile',
              name: 'settings-profile',
              component: () => import('@/views/settings/account/account-profile.vue'),
            },
            {
              path: 'notifications',
              name: 'settings-notifications',
              component: () => import('@/views/settings/account/account-notifications.vue'),
            },
            {
              path: 'sign-in',
              name: 'settings-sign-in',
              component: () => import('@/views/settings/account/account-sign-in.vue'),
            },
          ],
        },
        {
          path: '/search',
          name: 'search',
          component: SearchIndex,
        },
      ],
    },
    {
      path: '/jobs',
      component: Jobs,
      redirect: '/conversations', // Redirect to conversations for now
      children: [
        {
          path: '/jobs/:id/copilot-activation',
          name: 'jobs-copilot-activation',
          component: JobsCopilotActivation,
        },
      ],
    },

    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (About.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import('../views/AboutView.vue')
    // }
  ],
});

router.beforeEach(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // Check if this is a deep link navigation that we want to preserve
    const isDeepLinkNavigation = to.query.isDeepLink;

    // Clear URL parameters when navigating between pages to prevent parameter leakage,
    // but only if it's not a deep link navigation we want to preserve
    if (to.path !== from.path && Object.keys(to.query).length > 0 && !isDeepLinkNavigation) {
      next({ path: to.path, hash: to.hash });
      return;
    }

    const trackingService = TrackingService.instance;
    const queryWithoutToken = {
      ...to.query,
      ...(to.query?.token ? { token: '_redacted_' } : {}),
    };
    trackingService.registerPageVisit({
      name: 'Page View',
      path: to.path,
      search: SearchUtils.convertSearchObjectToSearchUrl(queryWithoutToken),
      title: to.name?.toString() || '',
      url: `${location.origin}${to.fullPath}`,
    });

    next();
  },
);

export default router;
