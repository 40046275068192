import type { GatedEducation } from '@/core/sourcing/search/types/search-gated-user-profile.type';

export function getUserProfileSortedGatedEducation(
  educations?: GatedEducation[],
): GatedEducation[] {
  if (!Array.isArray(educations)) {
    return [];
  }
  return educations.sort((a, b) => {
    // If both have issue dates, compare them
    if (a.dateIssuedUnixTs && b.dateIssuedUnixTs) {
      return b.dateIssuedUnixTs - a.dateIssuedUnixTs;
    }
    // If only one has an issue date, put it first
    if (a.dateIssuedUnixTs) return -1;
    if (b.dateIssuedUnixTs) return 1;
    // If neither has an issue date, maintain original order
    return 0;
  });
}
