import { useAtsEmployerApi } from '@/composables/useApi';
import { InternalError } from '../shared/errors/internal.error';
import MeService from '../shared/me/me.service';

export interface LaborMarketReportRequestInput {
  jobTitle: string;
  location: string;
  searchRadius: number; // in miles
}

export interface LaborMarketReportRequestOutput {
  success: boolean;
  referenceId: string;
  message: string;
}

export class CustomerSuccessPersistence {
  private meService = new MeService();

  /**
   * Submits a request for a labor market report
   * @param request The labor market report request data
   * @returns The response from the API
   */
  public async submitLaborMarketReport(
    request: LaborMarketReportRequestInput,
  ): Promise<LaborMarketReportRequestOutput | null> {
    const employerId = this.meService.employer?.id;

    if (!employerId) {
      throw new InternalError('No employer ID found', {
        error: 'Missing employer ID',
        status: 400,
        data: { request },
      });
    }

    const url = `/customer-success/labor-market-report/${employerId}`;
    const { data, error, statusCode } = await useAtsEmployerApi(url)
      .post(request)
      .json<LaborMarketReportRequestOutput>();

    if (error.value) {
      throw new InternalError('Failed to submit labor market report', {
        error: error.value,
        status: statusCode.value,
        data: { request, employerId },
      });
    }

    return data.value;
  }
}
