<script setup lang="ts">
import { computed } from 'vue';
import type { SearchGatedUserProfile } from '@/core/sourcing/search/types/search-gated-user-profile.type';

const props = defineProps<{
  pro: SearchGatedUserProfile;
  isExpanded: boolean;
}>();

const DEFAULT_ITEMS = 5;

const skillsToShow = computed(() => {
  if (!skillList.value.length) return [];
  return props.isExpanded ? skillList.value : skillList.value.slice(0, DEFAULT_ITEMS);
});

const remainingCount = computed(() => {
  if (!skillList.value.length) return 0;
  return Math.max(0, skillList.value.length - DEFAULT_ITEMS);
});

const getHighlightedSkill = (skill: string): string => {
  if (!props.pro?._highlight?.skills) return skill;
  const highlightedSkill = props.pro._highlight.skills.find((s) => s.value === skill);
  return highlightedSkill?.value || skill;
};

const skillList = computed(() => {
  if (!props.pro?._highlight?.skills) return [];
  return props.pro._highlight.skills.map((s) => s.value);
});
</script>

<template>
  <div>
    <div v-if="skillList.length === 0" class="sub-title" data-test-id="no-skills">
      No Skills Added
    </div>
    <template v-else>
      <div class="sub-title">
        Skills
        <span v-if="!isExpanded && remainingCount > 0" class="counter-text">
          {{ remainingCount }} more
        </span>
      </div>
      <div class="chips">
        <span
          v-for="skill in skillsToShow"
          :key="skill"
          class="chip ppc_highlighted"
          data-test-id="skill-chip"
          v-html="getHighlightedSkill(skill)"
        >
        </span>
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.chips {
  @apply flex flex-wrap gap-1;
}

.chip {
  @apply inline-block rounded-full bg-tint-40 px-2 py-0.5 text-xs leading-[18px] text-black;
  @apply border border-white;
}
.ppc_highlighted {
  b {
    @apply bg-highlight-600 !text-white;
  }
}
</style>
