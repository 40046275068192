<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import MessageSentAnimation from '@/components/Sourcing/MessageSentAnimation.vue';
import { nextTick } from 'vue';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import Autocomplete from '@/components/Shared/Input/Autocomplete/Autocomplete.vue';
import { pluralize } from '@/utils/strings/pluralize.util';
import MeService from '@/core/shared/me/me.service';
import { type Project, ProjectSource, type SearchProjects } from '@factoryfixinc/ats-interfaces';
import { ProjectPersistence } from '@/core/shared/project/project.persistence';
import { InternalError } from '@/core/shared/errors/internal.error';
import Checkbox from '../Input/Checkbox/Checkbox.vue';
import Divider from '../Divider/Divider.vue';
import BaseModal from '../Modals/BaseModal.vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';

const dialogService = new DialogService();
const meService = new MeService();
const messagingService = new MessagingService();
const projectPersistence = new ProjectPersistence();

const emit = defineEmits<{
  (e: 'submit', value: Project): void;
  (e: 'close', value: { messageSent: boolean }): void;
  (e: 'remove-pro', value: number): void;
}>();

const message = ref('');
const messageSentFlag = ref(false);
const showMore = ref(false);
const sendingMessage = ref(false);
const selectedJobId = ref<number | undefined>();
const myJobsFilter = ref(false);
const copilotJobsFilter = ref(false);
const atsJobsFilter = ref(false);
const projects = ref<Project[]>([]);
const isLoadingProjects = ref(false);

const modalProps = computed(() => dialogService.addProToJobModalProps);
const isOpen = computed(() => modalProps.value.isOpen);

const disableSendButton = computed(() => {
  return !selectedJobId.value || !recipientsCount.value;
});

const recipientsCount = computed(() => modalProps.value.pros?.length);

const sendMessage = async () => {
  try {
    sendingMessage.value = true;
    const project = projects.value.find((project) => project.jobId === selectedJobId.value);

    if (!project) {
      throw new Error('Project is not defined');
    }

    if (!modalProps.value.pros) {
      throw new Error('Pros are not defined');
    }

    await messagingService.sendMessageToRecipients(message.value, project, modalProps.value.pros);

    if (modalProps.value.additionalActionsBeforeSubmit) {
      await modalProps.value.additionalActionsBeforeSubmit(project);
    }

    messageSentFlag.value = true;
    emit('submit', project);
  } catch (error) {
    ErrorService.captureException(error);

    if (!(error instanceof InternalError)) {
      SnackbarService.critical('Something went wrong. Please try again.');
    }
  } finally {
    sendingMessage.value = false;
  }
};

async function close({ messageSent }: { messageSent: boolean }) {
  emit('close', { messageSent });
  await nextTick();
  resetForm();

  // Wait for close animation to finish
  setTimeout(() => {
    messageSentFlag.value = false;
  }, 500);
}

function buildSearchQuery(): Partial<SearchProjects> {
  const searchQuery: Partial<SearchProjects> = {};

  if (myJobsFilter.value && meService.userProfile) {
    searchQuery.teamIds = [meService.userProfile?.id];
  }

  if (copilotJobsFilter.value) {
    searchQuery.copilot = true;
  }

  if (atsJobsFilter.value) {
    searchQuery.source = ProjectSource.ATS;
  }

  return searchQuery;
}

function filterProjects(projects?: Project[]): Project[] {
  if (!projects) {
    return [];
  }

  if (modalProps.value.excludedJobIds) {
    return projects.filter((project) => !modalProps.value.excludedJobIds?.includes(project.jobId));
  }

  return projects;
}

async function fetchProjects() {
  try {
    isLoadingProjects.value = true;

    const result = await projectPersistence.searchProjects({
      employerId: meService.employer?.id as number,
      search: buildSearchQuery(),
    });

    projects.value = filterProjects(result.projects);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load projects. Please try again.');
  } finally {
    isLoadingProjects.value = false;
  }
}

function filterProject(
  value: string,
  query: string,
  item?: { value: unknown; raw: Record<string, unknown> },
): boolean {
  const renderedProject = renderProject(item?.raw).toLowerCase();

  const queryParts = query
    .toLowerCase()
    .split(' ')
    .filter((part) => part.length > 0);

  return queryParts.every((part) => renderedProject.includes(part));
}

function renderProject(project?: Record<string, unknown>) {
  let title = '';

  if (!project) {
    return '';
  }

  if (project.title) {
    title = `${project.title}`;
  }

  if (project.location) {
    title += ` • ${project.location}`;
  }

  if (project.jobId) {
    title += ` | #${project.jobId}`;
  }

  let copilotStatus = '';
  let copilotStatusColor = '';

  if (project.copilot) {
    copilotStatus = 'Copilot status: ON';
    copilotStatusColor = 'text-highlight-500';

    if (project.remoteJobId) {
      copilotStatus = 'Copilot Live (ATS)';
    }
  } else {
    copilotStatus = 'Copilot status: OFF';
    copilotStatusColor = 'text-shade-500';

    if (project.remoteJobId) {
      copilotStatus = 'Copilot status: OFF (ATS)';
    }
  }

  return `
    <div class="flex items-center justify-between">
      <span class="truncate text-sm">${title}</span>
      <span class="${copilotStatusColor} text-xs">${copilotStatus}</span>
    </div>
  `;
}

function resetForm() {
  selectedJobId.value = undefined;
  message.value = '';
  myJobsFilter.value = false;
  copilotJobsFilter.value = false;
  atsJobsFilter.value = false;
}

watch(isOpen, async (open) => {
  if (open) {
    if (recipientsCount.value === 1) {
      showMore.value = true;
    }

    await fetchProjects();
  }
});

watch([myJobsFilter, copilotJobsFilter, atsJobsFilter], async () => {
  await fetchProjects();
});
</script>

<template>
  <BaseModal
    :model-value="isOpen"
    :is-loading="sendingMessage"
    :is-submit-disabled="disableSendButton"
    :show-close-button="true"
    :show-cancel-button="true"
    :show-heading="!messageSentFlag"
    :show-actions="!messageSentFlag"
    :title="`Add ${pluralize(modalProps.pros?.length, 'candidate')} to job`"
    submit-button-text="Add to job"
    @submit="sendMessage"
    @cancel="close({ messageSent: false })"
  >
    <MessageSentAnimation
      v-if="messageSentFlag"
      @animation:complete="close({ messageSent: true })"
    />

    <div v-else>
      <!-- Job Selector -->
      <div class="mt-5">
        <span class="text-sm font-bold">Select job*</span>
        <div class="mt-1">
          <Autocomplete
            v-model="selectedJobId"
            :disabled="sendingMessage"
            :loading="isLoadingProjects"
            :items="projects"
            :plain="true"
            :render-item="renderProject"
            :custom-filter="filterProject"
            clearable
            item-title="title"
            item-value="jobId"
            placeholder="Select a job"
          >
            <template #prepend-item>
              <div class="flex gap-6 px-4 pb-4 pt-2">
                <span class="text-xs text-shade-800">Filter by:</span>

                <div class="flex items-center gap-2">
                  <Checkbox v-model="myJobsFilter"></Checkbox>
                  <span class="text-xs text-shade-800">My jobs</span>
                </div>

                <div class="flex items-center gap-2">
                  <Checkbox v-model="copilotJobsFilter"></Checkbox>
                  <span class="text-xs text-shade-800">Copilot jobs</span>
                </div>

                <div class="flex items-center gap-2">
                  <Checkbox v-model="atsJobsFilter"></Checkbox>
                  <span class="text-xs text-shade-800">ATS jobs</span>
                </div>
              </div>

              <Divider class="mb-1" />
            </template>
          </Autocomplete>
        </div>
      </div>

      <!-- Textarea -->
      <div v-if="modalProps.showMessageField" class="mt-5">
        <span class="text-sm font-bold">Send first message</span>
        <span class="text-sm text-[#979797]">&nbsp;(optional)</span>
        <textarea
          v-model="message"
          :disabled="sendingMessage"
          class="mt-1 w-full resize-none rounded-md border border-tint-80 px-4 py-4 text-shade-900 placeholder:text-shade-800 focus:outline-none focus:ring-2 focus:ring-highlight-500"
          placeholder="Type your message here"
          rows="4"
        />
      </div>

      <div class="mt-5"></div>
    </div>
  </BaseModal>
</template>
