import { useAtsCandidateDiscoveryApi } from '@/composables/useApi';
import type { ViewedCandidate } from './types/viewed-candidate';

export class ViewedCandidatePersistence {
  public async markCandidateAsViewed(proPreferenceId: number): Promise<void> {
    const { error } = await useAtsCandidateDiscoveryApi(`/notify/viewed-candidate`).post({
      candidateId: proPreferenceId,
    });

    if (error.value) {
      throw new Error(error.value);
    }
  }

  public async fetchViewedCandidates(): Promise<ViewedCandidate[]> {
    const { data, error } = await useAtsCandidateDiscoveryApi<ViewedCandidate[]>(
      `/search-talent/viewed-candidates`,
    )
      .get()
      .json();

    if (error.value || !data.value) {
      throw new Error(error.value);
    }

    return data.value;
  }
}
