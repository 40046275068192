/**
 * Checks if the user is currently selecting text on the page.
 * Useful for components that need to distinguish between text selection
 * and other click interactions.
 * @returns {boolean} True if text is selected, false otherwise
 */
export const isTextSelected = (): boolean => {
  const selection = window.getSelection();
  return selection !== null && selection.toString().length > 0;
};
