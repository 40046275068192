import DateUtils from '@/utils/date-utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export const formatUTCDate = (
  date: string | Date,
  format = 'MM/DD/YYYY',
  defaultVal = '',
): string => {
  const momentDate = dayjs(date);
  if (!momentDate.isValid()) return defaultVal;
  return momentDate.utc().format(format);
};

export function isProRecentlyActive(lastActiveDateString?: string): boolean {
  if (!lastActiveDateString) {
    return false;
  }
  return DateUtils.age(DateUtils.dateStringToDate(lastActiveDateString)).asDays() <= 90;
}

export function getProActivityRelativeDateTime(lastActiveDateString?: string): string {
  if (!lastActiveDateString) {
    return 'Inactive';
  }

  const lastActive = dayjs(lastActiveDateString);
  if (!lastActive.isValid()) {
    return 'Inactive';
  }

  return `Active ${lastActive.fromNow()}`;
}
