import ProUnlockPersistence from './pro-unlock.persistence';
import type { ProUnlockResult, ProUnlockSource } from './types/pro-unlock-source.type';
import MeService from '@/core/shared/me/me.service';
import { SearchService } from '../search/search.service';
import SelectedCandidateService from '../selected-candidate/selected-candidate.service';

export default class ProUnlockService {
  private persistence = new ProUnlockPersistence();
  private searchService = new SearchService();
  private meService = new MeService();
  private selectedCandidateService = new SelectedCandidateService();

  /**
   * TODO: Remove this method when the old search page is removed
   * (https://linear.app/factoryfix/issue/ONE-3172/remove-legacy-code-when-we-remove-the-old-sourcing-search)
   */
  async unlockProUserProfilesLegacy(
    userProfileIds: number[],
    source: ProUnlockSource,
  ): Promise<ProUnlockResult | undefined> {
    const employerId = this.meService.employer?.id;
    if (!employerId || !userProfileIds.length || !source) return;

    const results = await this.persistence.unlockProUserProfiles({
      employerId,
      userProfileIds,
      source,
    });
    // this.$mixpanelAnalytics.proUnlock({
    //   source,
    //   numberOfPros: userProfileIds.length,
    // });
    this.searchService.searchCandidates();

    const selectedCandidate = this.selectedCandidateService.candidate;
    if (selectedCandidate?.id) {
      this.selectedCandidateService.fetchCandidate(selectedCandidate.id);
    }

    return results;
  }

  /**
   * Unlocks the pro user profiles
   * @param userProfileIds - The user profile ids to unlock
   * @param source - The source of the unlock
   * @returns The results of the unlock
   */
  async unlockProUserProfiles(
    userProfileIds: number[],
    source: ProUnlockSource,
  ): Promise<ProUnlockResult | undefined> {
    const employerId = this.meService.employer?.id;
    if (!employerId || !userProfileIds.length || !source) return;
    return this.persistence.unlockProUserProfiles({
      employerId,
      userProfileIds,
      source,
    });
  }
}
