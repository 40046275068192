import AuthenticationService from '@/core/shared/authentication/authentication.service';
import { type UseFetchOptions, createFetch, useFetch } from '@vueuse/core';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import MeService from '@/core/shared/me/me.service';

type CreateFetchApiOptions = Partial<RequestInit> & {
  parseError?: boolean;
  useAccessToken?: boolean | ((method: string | undefined, url: string) => boolean);
};

function shouldUseAccessToken(
  method: string | undefined,
  url: string,
  options?: CreateFetchApiOptions,
): boolean {
  const useAccessToken = options?.useAccessToken;
  if (typeof useAccessToken === 'function') {
    return useAccessToken(method, url);
  }
  return useAccessToken ?? false;
}

const createFetchApi = (baseUrl: string, fetchOptions?: CreateFetchApiOptions): typeof useFetch => {
  // https://vueuse.org/core/useFetch/
  return createFetch({
    baseUrl,
    fetchOptions,
    options: {
      updateDataOnError: true,
      async beforeFetch({ options, url }) {
        const authenticationService = getAuthService();
        const token = shouldUseAccessToken(options.method, url, fetchOptions)
          ? await authenticationService.getValidAccessToken()
          : authenticationService.idToken;

        options.headers = {
          ...(options.headers || {}),
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json; charset=utf-8',
        };

        return { options };
      },
      fetch: fetchOptions?.useAccessToken
        ? (fetchWithRetryOn401 as UseFetchOptions['fetch'])
        : undefined,
      onFetchError(ctx) {
        if (fetchOptions?.parseError) {
          ctx.error = JSON.stringify({ ...ctx.error, ...ctx.data });
        }

        const status = ctx.response?.status ?? HttpStatus.INTERNAL_SERVER_ERROR;
        const isNotModified = status === HttpStatus.NOT_MODIFIED;
        const isUnauthorized = status === HttpStatus.UNAUTHORIZED;
        const shouldIgnoreError = isNotModified;

        if (shouldIgnoreError) {
          ctx.error = null;
        }

        if (isUnauthorized) {
          getAuthService().logout();
          getMeService().redirectToUI2('/employer/auth/logout');
        }

        return ctx;
      },
    },
  });
};

export const useApiGateway = createFetchApi(import.meta.env.VITE_API_GATEWAY_URL);
export const useFFRestApi = createFetchApi(`${import.meta.env.VITE_FF_REST_API_URL}/v4`);
export const useAtsSubscriptionApi = createFetchApi(import.meta.env.VITE_ATS_SUBSCRIPTION_API_URL);
export const useAtsHelpDeskApi = createFetchApi(import.meta.env.VITE_ATS_HELP_DESK_API_URL);
export const useAtsEmployerApi = createFetchApi(import.meta.env.VITE_ATS_EMPLOYER_API_URL);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAtsEmployerApiWithOptions = (options?: CreateFetchApiOptions) =>
  createFetchApi(import.meta.env.VITE_ATS_EMPLOYER_API_URL, {
    ...options,
    useAccessToken: true,
  });
export const useAtsConversationApi = createFetchApi(import.meta.env.VITE_ATS_CONVERSATION_API_URL, {
  useAccessToken: true,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAtsConversationApiWithOptions = (options?: CreateFetchApiOptions) =>
  createFetchApi(import.meta.env.VITE_ATS_CONVERSATION_API_URL, {
    ...options,
    useAccessToken: true,
  });
export const useAtsProjectsApi = createFetchApi(import.meta.env.VITE_ATS_PROJECT_URL, {
  useAccessToken: useAccessTokenForAtsProjectUrl,
});
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAtsProjectsApiWithOptions = (options?: CreateFetchApiOptions) =>
  createFetchApi(import.meta.env.VITE_ATS_PROJECT_URL, {
    ...options,
    useAccessToken: useAccessTokenForAtsProjectUrl,
  });
export const useAtsApplicationScoringApi = createFetchApi(
  import.meta.env.VITE_ATS_APPLICATION_SCORING,
  { useAccessToken: true },
);

export const useAtsCandidateDiscoveryApi = createFetchApi(
  import.meta.env.VITE_ATS_CANDIDATE_DISCOVERY_URL,
  { useAccessToken: true },
);

export const useAtsConnectApi = createFetchApi(import.meta.env.VITE_ATS_CONNECT_URL);

export const useAtsEmployerFeatureApi = createFetchApi(
  import.meta.env.VITE_ATS_EMPLOYER_FEATURE_API_URL,
);
export const useAtsUserPreferenceApi = createFetchApi(
  import.meta.env.VITE_ATS_USER_PREFERENCE_API_URL,
);
export const useJobBoardSearchApi = createFetchApi(import.meta.env.VITE_JOB_BOARD_SEARCH_API_URL);
export const useAtsJobApi = createFetchApi(import.meta.env.VITE_ATS_JOB_API_URL);

const getAuthService = (() => {
  let authService: AuthenticationService | undefined;
  return () => {
    if (!authService) authService = new AuthenticationService();
    return authService;
  };
})();

const getMeService = (() => {
  let meService: MeService | undefined;
  return () => {
    if (!meService) meService = new MeService();
    return meService;
  };
})();

async function fetchWithRetryOn401(
  ...capturedParams: Parameters<typeof fetch>
): ReturnType<typeof fetch> {
  let result = await fetch(...capturedParams);
  if (result.status === HttpStatus.UNAUTHORIZED) {
    const [resource, options, ...otherParams] = capturedParams;
    // request failed due to expired access token. refresh the token.
    const authService = getAuthService();
    await authService.refreshAccessToken();

    // replace token in the Authorization header and retry the request.
    const newToken = authService.accessToken;
    const newOptions = {
      ...options,
      headers: {
        ...options?.headers,
        Authorization: `Bearer ${newToken}`,
      },
    };
    result = await fetch(resource, newOptions, ...otherParams);
  }
  return result;
}

/**
 * Hack to avoid using access tokens on endpoints that don't support them.
 * Endpoints that don't support access tokens:
 * - POST /project/employer/:employerId
 * - POST /project/:projectId/employer/:employerId/duplicate-project
 * - PATCH /project/:projectId/employer/:employerId/update-copilot-status
 * - PATCH /project/:projectId/employer/:employerId/update-project-assignee
 */
function useAccessTokenForAtsProjectUrl(method: string | undefined, url: string) {
  const normalizeMethod = method?.toUpperCase();
  if (normalizeMethod === 'POST') {
    const createEmployerProjectRegex = /^\/project\/employer\/\d+$/;
    const duplicateProjectRegex = /^\/project\/\d+\/employer\/\d+\/duplicate-project$/;

    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    if (createEmployerProjectRegex.test(path) || duplicateProjectRegex.test(path)) {
      return false;
    }
  }

  if (normalizeMethod === 'PATCH') {
    const updateCopilotStatusRegex = /^\/project\/\d+\/employer\/\d+\/update-copilot-status$/;
    const updateProjectAssigneeRegex = /^\/project\/\d+\/employer\/\d+\/update-project-assignee$/;

    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    if (updateCopilotStatusRegex.test(path) || updateProjectAssigneeRegex.test(path)) {
      return false;
    }
  }

  // for all other cases, use access token.
  return true;
}
