import { ref } from 'vue';
import { defineStore } from 'pinia';
import type { JobApplicant } from '@factoryfixinc/ats-interfaces';

export const useJobApplicantStore = defineStore('job-applicant', () => {
  const jobApplicants = ref<JobApplicant[]>([]);
  const isLoading = ref<boolean>(false);

  return {
    // State
    jobApplicants,
    isLoading,
  };
});
