export enum TrackingActionName {
  JOB_STARTED = 'Job Started',
  COPILOT_ENABLE_STARTED = 'Copilot Enable Started',
  COPILOT_ENABLE_COMPLETED = 'Copilot Enable Completed',
  COPILOT_ENABLE_ABANDONED = 'Copilot Enable Abandoned',
  COPILOT_ENABLE_CHANGES_SAVED = 'Copilot Enable Changes Saved',
  COPILOT_DISABLED = 'Copilot Disabled',
  COPILOT_SCORE_FEEDBACK_SUBMITTED = 'Copilot Score Feedback Submitted',
  SOURCING_CONVERSATION_VIEWED = 'Sourcing Conversation Viewed',
  CANDIDATE_FILTER = 'Candidate Filter',
  PPC_CLICK = 'PPC Click',
  UNLOCK = 'Unlock',
  BULK_ACTION_START = 'Bulk Action Start',
  MESSAGE_START = 'Message Start',
  MESSAGE_SENT = 'Message Sent',
  APPLICATION_STATUS_CHANGED = 'Application Status Changed',
  SCORE_SUMMARY_VIEWED = 'Score Summary Viewed',
  SCORE_SUMMARY_EXTRA_REASONING_VIEWED = 'Score Summary Extra Reasoning Viewed',
  TEMPLATE_INSERTED = 'Template Inserted',
  APPLICATIONS_TAB_VIEWED = 'Applications Tab Viewed ',
  RESUME_TAB_VIEWED = 'Resume Tab Viewed',
  INVITE_TEAM_START = 'Invite Team Start',
  INVITE_TEAM_SENT = 'Invite Team Sent',
  PROFILE_CLICK = 'Profile Click',
  PROFILE_DOWNLOADED = 'Profile Downloaded',
  JOB_ARCHIVED = 'Job Archived',
  JOB_UNARCHIVED = 'Job Unarchived',
  JOB_DESCRIPTION_GENERATED = 'Job Description Generated',
  JOB_DELETED = 'Job Deleted',
  JOB_RESTORED = 'Job Restored',
  PROJECT_CARD_CLICKED = 'Project Card Clicked',
  SOURCING_PAGE_JOBS_FILTERED_OR_SEARCHED = 'Sourcing Page Jobs Filtered or Searched',
  RESUME_VIEWED_IN_SOURCING = 'Resume Viewed in Sourcing',
  NOTE_ADDED = 'Note Added',
  CONVERSATIONS_PAGE_JOBS_FILTERED_OR_SEARCHED = 'Conversations Page Jobs Filtered or Searched',
  CONVERSATIONS_PAGE_JOBS_SELECTED = 'Conversations Page Jobs Selected',
  CONVERSATIONS_PAGE_JOBS_SELECT_RESET = 'Conversations Page Jobs Select Reset',
  CONVERSATIONS_FILTERED_AND_SORTED_OR_SEARCHED = 'Conversations Filtered Sorted or Searched',
  CONVERSATION_SELECTED = 'Conversation Selected',
  RESUME_TAB_RESUME_DOWNLOADED = 'Resume Tab Resume Downloaded',
  BULK_ACTION_CHANGE_CONVERSATION_STATUS = 'Bulk Action Change Conversation Status',
  BULK_ACTION_EXPORT_APPLICANTS = 'Bulk Action Export Applicants in XLSX File',
  SCREENERS_GENERATED = 'Screeners Generated',
  NEW_SEARCH_STARTED = 'New Search Started',
  NEW_SEARCH_COMPLETED = 'New Search Completed',
  NEW_SEARCH_ABANDONED = 'New Search Abandoned',
  SCHEDULED_CHANGES_MODAL_OPENED = 'Scheduled Changes Modal Opened',
  SCHEDULED_UPDATE_VIEWED = 'Scheduled Update Viewed',
  SCHEDULED_UPDATES_REGENERATED = 'Scheduled Updates Regenerated',
  JOB_DESCRIPTION_PARSING_ERROR = 'Job Description Parsing Error',
}
export enum TrackingCopilotSource {
  ACTION_MENU = 'action_menu',
  COPILOT_ACTIVATION_PAGE = 'copilot_activation_page',
  DUPLICATE_JOB = 'duplicate_job',
  EDIT_JOB = 'edit_job',
  PLUS_NEW_JOB_BUTTON = 'plus_new_job_button',
  PLUS_NEW_SEARCH_BUTTON = 'plus_new_search_button',
  PROMO_BANNER = 'promo_banner',
  TOGGLE = 'toggle',
}

export enum TrackingJobDescriptionParsingError {
  BULLET_POINT_IN_ANOTHER_LINE = 'bullet_point_in_another_line',
  BOLD_ASTERISKS_NOT_BEING_FORMATTED = 'bold_asterisks_not_being_formatted',
  BOLD_ASTERISKS_IN_ANOTHER_LINE = 'bold_asterisks_in_another_line',
}

export enum TrackingJobDescriptionParsingErrorSource {
  // When the user types in the editor and we parse the data to update local state
  EDITOR_DATA_CHANGE_PARSING = 'editor_data_change_parsing',
  // When the user initializes the editor and we parse the data to set initial local state
  EDITOR_INITIALIZATION_PARSING = 'editor_initialization_parsing',
  // When the user pastes content into the editor and we parse the data to update local state
  EDITOR_PASTE_PARSING = 'editor_paste_parsing',
}
