<script setup lang="ts">
import VuePdfEmbed, { type VuePdfEmbedMethods } from 'vue-pdf-embed';
import { computed, ref } from 'vue';

const props = defineProps<{
  modelValue: boolean;
  proName: string;
  resumeUrl: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
  (e: 'rendering-failed'): void;
  (e: 'loading-failed'): void;
}>();

const isLoading = ref(true);
const fileRef = ref<VuePdfEmbedMethods | null>(null);

const resumeTitle = computed(() => {
  if (props.proName.endsWith('s')) {
    return `${props.proName}'s resume`;
  }

  return `${props.proName}'s resume`;
});

function downloadResume() {
  if (!fileRef.value) {
    return;
  }

  fileRef.value.download(resumeTitle.value);
}
</script>

<template>
  <v-dialog :model-value="modelValue" persistent>
    <div class="dialog-max-width mx-auto w-full">
      <!-- Close dialog icon -->
      <div class="flex justify-end">
        <div class="flex cursor-pointer" @click="emit('update:modelValue', false)">
          <img src="@/assets/svg/close-white.svg" alt="Close" />
          <span class="ml-1 text-sm font-semibold text-white">Close</span>
        </div>
      </div>

      <!-- Dialog Window -->
      <div class="mt-2 rounded-2xl bg-white px-6 py-2">
        <!-- Dialog Header -->
        <div class="sticky flex h-14 items-center justify-between">
          <div class="font-serif font-650">{{ resumeTitle }}</div>

          <button class="flex rounded-md bg-shade-900 px-3 py-1.5" @click="downloadResume">
            <img src="@/assets/svg/download-white.svg" class="mr-1 w-5" alt="Download" />
            <span class="text-sm font-semibold text-white">Download</span>
          </button>
        </div>

        <!-- Resume -->
        <div
          v-if="props.resumeUrl"
          class="resume-max-height h-full overflow-y-auto border border-tint-80"
        >
          <v-skeleton-loader v-if="isLoading" boilerplate type="heading, subtitle, paragraph@10" />

          <VuePdfEmbed
            :source="props.resumeUrl"
            ref="fileRef"
            @rendered="isLoading = false"
            @loading-failed="emit('loading-failed')"
            @rendering-failed="emit('rendering-failed')"
          >
            <template #after-page>
              <div class="h-8 bg-tint-60 shadow-inner" />
            </template>
          </VuePdfEmbed>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<style scoped>
.dialog-max-width {
  max-width: 956px;
}

.resume-max-height {
  max-height: calc(100vh - 116px);
}
</style>
