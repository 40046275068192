<script setup lang="ts">
import { computed } from 'vue';
import { useDisplay } from 'vuetify';
import SettingsBar from '@/components/Shared/LayoutBar/SettingsBar/SettingsBar.vue';

const display = useDisplay();
const isDesktop = computed(() => display.mdAndUp.value);
</script>
<template>
  <div>
    <v-navigation-drawer width="256" color="shade-860" :permanent="isDesktop">
      <SettingsBar />
    </v-navigation-drawer>
    <RouterView />
  </div>
</template>
