<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import TaxonomyService from '@/core/shared/taxonomy/taxonomy.service';
import { SelectedFilterType } from '@/core/sourcing/search/types/selected-filter-type.type';
import JobTitleService from '@/core/shared/job-title/job-title.service';
import { SearchService } from '@/core/sourcing/search/search.service';
import Autocomplete from '@/components/Shared/Input/Autocomplete/Autocomplete.vue';
import { debounce } from 'radash';
import type { PlacePrediction } from '@/core/sourcing/search/types/place-prediction.type';
import type { SelectedFilter } from '@/core/sourcing/search/types/selected-filter.type';
import { SearchService as SharedSearchService } from '@/core/shared/search/search.service';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { useRoute, useRouter } from 'vue-router';
import { ErrorService } from '@/core/shared/errors/error.service';

const emit = defineEmits(['clearSelectedCandidate', 'search']);

const taxonomyService = new TaxonomyService();
const jobTitleService = new JobTitleService();
const searchService = new SearchService();
const sharedSearchService = new SharedSearchService();
const messagingService = new MessagingService();
const route = useRoute();
const router = useRouter();

const listOfLastActive = [
  { id: 7, title: '7 days' },
  { id: 30, title: '30 days' },
  { id: 60, title: '60 days' },
  { id: 90, title: '90 days' },
  { id: 365, title: '1 year' },
];

const distanceItems = [
  { id: 10, title: '10 miles' },
  { id: 20, title: '20 miles' },
  { id: 30, title: '30 miles' },
  { id: 40, title: '40 miles' },
  { id: 50, title: '50 miles' },
  { id: 60, title: '60 miles' },
  { id: 70, title: '70 miles' },
  { id: 80, title: '80 miles' },
  { id: 90, title: '90 miles' },
  { id: 100, title: '100+ miles' },
];

const isSearchButtonDisabled = computed(() => !sharedSearchService.hasFilters());

const hasLocationFilter = computed(() => !!sharedSearchService.location);
const distanceHintText = computed(() =>
  hasLocationFilter.value ? 'Distance' : 'Distance (must select location)',
);

const jobTitles = computed(() =>
  jobTitleService.jobTitles.map((jobTitle) => ({
    title: jobTitle.title ?? '',
    id: jobTitle.id,
  })),
);

/** Logic to populate address predictions */
const populateAddressPredictions = debounce({ delay: 300 }, _populateAddressPredictions);
const searchLocationText = ref<string>('');
const isLoadingLocations = ref(false);
const locationItems = ref<PlacePrediction[]>([]);
// Need to map the location items to match the autocomplete structure
const listOfLocations = computed(() =>
  locationItems.value.map((item) => ({ title: item.value, id: item.id })),
);
async function _populateAddressPredictions(address: string) {
  isLoadingLocations.value = true;
  locationItems.value = await searchService.getAddressPredictions(address);
  isLoadingLocations.value = false;
}
async function onSearchLocation(value: string) {
  searchLocationText.value = value;
  if (!value || typeof value !== 'string') {
    return;
  }
  await populateAddressPredictions(value);
}
const isLocationFocused = ref(false);
function onLocationFocused(value: boolean) {
  isLocationFocused.value = value;
}
const locationHintText = computed(() =>
  isLocationFocused.value ? 'Type to search' : 'Search by location',
);
const noDataText = computed(() =>
  searchLocationText.value.trim() === '' ? 'Type to search' : 'No results',
);
//--> End of location logic

function handleValueChange(type: SelectedFilterType, value: SelectedFilter['value'] | undefined) {
  // Ensure the filter is removed if the value is empty
  if (!value || (Array.isArray(value) && !value.length)) {
    searchService.removeFilter(type);

    // If the filter is job title, remove years of experience filter as well
    if (type === SelectedFilterType.JOB_TITLE) {
      searchService.removeFilter(SelectedFilterType.YEARS_OF_EXPERIENCE);
    }
    return;
  }
  const filter = {
    type,
    value,
    objectId: value as string,
  } as SelectedFilter;

  // Do we need to update the filter? or add it to the list?
  const existingFilter = searchService.selectedFilters.find((f) => f.type === type);
  if (!existingFilter) {
    searchService.selectedFilters.push(filter);
  } else {
    searchService.updateFilter(filter);
  }
}

async function handleSearch() {
  if (sharedSearchService.isSearchingCandidates) {
    return;
  }

  try {
    sharedSearchService.isSearchingCandidates = true;
    emit('clearSelectedCandidate');
    messagingService.removeSelectedProUserProfiles();

    await sharedSearchService.searchCandidatesWithAdditionalInformation({
      page: 1,
    });

    const urlQuery = await sharedSearchService.resolveUrlQuery(route.query, {
      localStateIsPriority: true,
    });

    await router.replace({ query: urlQuery });

    emit('search');
  } catch (error) {
    ErrorService.captureException(error);
  } finally {
    sharedSearchService.isSearchingCandidates = false;
  }
}

// Will set the distance to 30 if the location is selected
// and if the distance is not set then will reset it
const selectedLocation = computed(() => sharedSearchService.location);
watch(selectedLocation, (newValue) => {
  if (!newValue) {
    sharedSearchService.distance = undefined;
  } else if (!sharedSearchService.distance) {
    sharedSearchService.distance = 30;
  }
});

const keywordInput = ref<HTMLInputElement>();

defineExpose({
  focusKeywordInput() {
    if (!keywordInput.value) {
      return;
    }
    keywordInput.value.focus();
  },
});
</script>
<template>
  <div class="pro-search-form">
    <div class="form-container">
      <div class="title">Search</div>
      <div>
        <label for="keyword" class="!mt-0">Keyword</label>
        <input
          ref="keywordInput"
          v-model="sharedSearchService.keyword"
          id="keyword"
          type="text"
          placeholder="Search by keyword"
          class="input-text"
          data-test-id="project-search-text-filter"
          @update:model-value="handleValueChange(SelectedFilterType.KEYWORD, $event)"
        />
        <label>Location</label>
        <autocomplete
          v-model="sharedSearchService.location"
          :placeholder="locationHintText"
          :items="listOfLocations"
          :loading="isLoadingLocations"
          @update:search="onSearchLocation"
          @update:focused="onLocationFocused"
          :no-data-text="noDataText"
          open-text="Type to search"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.LOCATION, $event)"
        />
        <autocomplete
          class="mt-2"
          v-model="sharedSearchService.distance"
          :placeholder="distanceHintText"
          :items="distanceItems"
          item-title="title"
          item-value="id"
          :disabled="!hasLocationFilter"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.AROUND_RADIUS, $event)"
        />
        <label>Job title</label>
        <autocomplete
          v-model="sharedSearchService.jobTitle"
          placeholder="Search by job title"
          :items="jobTitles"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.JOB_TITLE, $event)"
        />
        <label>Skills</label>
        <autocomplete
          v-model="sharedSearchService.skills"
          placeholder="Search by skills"
          :items="taxonomyService.knowledgeDisciplines"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.KNOWLEDGE_DISCIPLINE, $event)"
        />
        <label>Machines & technologies</label>
        <autocomplete
          v-model="sharedSearchService.machines"
          placeholder="Search by machines & technologies"
          :items="taxonomyService.machines"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.TECHNOLOGY, $event)"
        />
        <label>Brand</label>
        <autocomplete
          v-model="sharedSearchService.brands"
          placeholder="Search by brands"
          :items="taxonomyService.brands"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.BRAND, $event)"
        />
        <label>Last active</label>
        <autocomplete
          v-model="sharedSearchService.lastActive"
          placeholder="Search by last active"
          :items="listOfLastActive"
          item-title="title"
          item-value="id"
          clearable
          dark
          @update:model-value="handleValueChange(SelectedFilterType.LAST_ACTIVE, $event)"
        />
      </div>
    </div>
    <div class="sticky-button">
      <v-btn
        :ripple="false"
        variant="flat"
        class="search-button"
        :disabled="isSearchButtonDisabled"
        @click="handleSearch"
        :loading="sharedSearchService.isSearchingCandidates"
      >
        Search
      </v-btn>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.pro-search-form {
  @apply flex h-full flex-col;
  .form-container {
    @apply flex-1 overflow-y-auto pl-4 pr-4 pt-4;

    /* Webkit browsers (Chrome, Safari, newer versions of Edge) */
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      @apply rounded-lg bg-shade-600;
    }
    .input-text {
      @apply w-full rounded-md bg-shade-840 px-3 py-2 text-sm focus:outline-none;
      @apply placeholder:text-[13px] placeholder:text-shade-800  placeholder:opacity-95;
      @apply leading-6;
      @apply border border-transparent;
      @apply transition-colors duration-300;
      &:hover {
        @apply border-tint-0;
      }
      &:focus {
        @apply border-highlight-500;
      }
    }
    label {
      @apply mb-1 mt-5 inline-block text-xs font-semibold;
    }
  }
  .title {
    @apply mb-5 mt-1 font-serif text-base font-black leading-6;
  }
  .sticky-button {
    @apply sticky bottom-0 p-4;
  }
}
.search-button {
  @apply !h-auto bg-highlight-500 !px-4 !py-2.5 !text-sm !font-bold;
  @apply !normal-case leading-[18px] !tracking-normal text-white;
  @apply w-full !transition-colors !duration-300;
  &:disabled {
    @apply !bg-tint-80 !text-shade-500 !text-opacity-100 !opacity-100;
  }
}
</style>
