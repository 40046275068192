export const EMAIL_MASK = '****@***.**';
export const PHONE_MASK = '***-****';

export function maskSensitiveInfo(text: string): string {
  return (
    text
      // Remove email addresses - updated pattern to be more precise
      .replace(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g, EMAIL_MASK)
      // Remove phone numbers in various formats - updated pattern to be more inclusive
      .replace(
        /(?:\+?\d{1,3}[-.\s]?)?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}|\+\d{1,3}[-.\s]?\d{2}[-.\s]?\d{5}[-.\s]?\d{4}/g,
        PHONE_MASK,
      )
  );
}
