<script setup lang="ts">
import { computed, ref } from 'vue';
import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import ChevronDownIcon from '@/assets/svg/chevron-down-16.svg?component';
import { ApplicationBulkActions } from '@/core/conversations/conversation-index/conversation-index.type';

const props = withDefaults(
  defineProps<{
    areAllSelected: boolean;
    areSomeSelected: boolean;
    isSendingBulkAction: boolean;
  }>(),
  { areAllSelected: false, areSomeSelected: false },
);

const emit = defineEmits<{
  (e: 'onSelectAll', payload: boolean): void;
  (e: 'onBulkAction', action: ApplicationBulkActions): void;
}>();

const isOpen = ref(false);
const isChecked = computed(() => props.areAllSelected || props.areSomeSelected);
const isMinusCheck = computed(() => !props.areAllSelected && props.areSomeSelected);
const isDisabled = computed(() => props.isSendingBulkAction || !isChecked.value);

const handleUpdateModelValue = (value: boolean) => {
  isOpen.value = value;
};

const handleCheckSelection = () => {
  if (props.isSendingBulkAction) return;
  emit('onSelectAll', !isChecked.value);
};
</script>

<template>
  <div :class="{ 'opacity-50': isDisabled }">
    <span @click.stop="handleCheckSelection" class="cursor-pointer">
      <Checkbox
        :model-value="isChecked"
        :is-minus-check="isMinusCheck"
        class="mb-[-2px] mr-2 inline-block h-4 w-4"
      />
    </span>
    <v-menu
      :model-value="isOpen"
      offset-y
      location="bottom"
      transition="slide-y-transition"
      @update:model-value="handleUpdateModelValue"
      :disabled="isDisabled"
    >
      <template #activator="{ props }">
        <button v-bind="props" class="-ml-1">
          <ChevronDownIcon
            class="transition-all"
            :class="{
              'rotate-180': isOpen,
              'text-tint-80': !isChecked,
              'text-shade-900': isChecked,
            }"
          />
        </button>
      </template>
      <div class="-ml-5 mt-0.5 rounded-md bg-white py-2 shadow-menu">
        <v-list>
          <v-list-item @click="emit('onBulkAction', ApplicationBulkActions.MOVE_TO_REVIEWED)">
            <v-list-item-title>Move to Reviewed</v-list-item-title>
          </v-list-item>
          <v-list-item @click="emit('onBulkAction', ApplicationBulkActions.MOVE_TO_REJECTED)">
            <v-list-item-title>Reject</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="emit('onBulkAction', ApplicationBulkActions.MOVE_TO_REJECTED_W_REASON)"
          >
            <v-list-item-title>Reject w/ reason</v-list-item-title>
          </v-list-item>
          <v-list-item @click="emit('onBulkAction', ApplicationBulkActions.EXPORT_CANDIDATES)">
            <v-list-item-title>Export</v-list-item-title>
          </v-list-item>
          <v-list-item @click="emit('onBulkAction', ApplicationBulkActions.ADD_TO_ANOTHER_JOB)">
            <v-list-item-title>Add to another job</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </v-menu>
  </div>
</template>

<style scoped>
.v-list-item {
  @apply !m-0 cursor-pointer px-1 py-3 hover:bg-tint-40;
  min-height: 34px;

  .v-list-item-title {
    @apply text-xs text-shade-900  !important;
  }
}
</style>
