import type { JobApplicant } from '@factoryfixinc/ats-interfaces';
import { JobApplicantPersistence } from './job-applicant.persistence';
import { useJobApplicantStore } from './job-applicant.store';

export default class JobApplicantService {
  private readonly persistence = new JobApplicantPersistence();
  private readonly store = useJobApplicantStore();

  getJobApplicantsByUserProfile(userProfileId: number): JobApplicant[] | undefined {
    return this.store.jobApplicants.filter(
      (applicant) => applicant.userProfileId === userProfileId,
    );
  }

  async getJobApplicantsByUserProfileIds(
    employerId: number,
    userProfileIds: number[],
  ): Promise<JobApplicant[]> {
    try {
      this.store.isLoading = true;
      const jobApplicants = await this.persistence.getJobApplicantsByUserProfileIds(
        employerId,
        userProfileIds,
      );
      this.store.jobApplicants = jobApplicants;

      return jobApplicants;
    } finally {
      this.store.isLoading = false;
    }
  }
}
