<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';
import type {
  GatedEducation,
  SearchGatedUserProfile,
} from '@/core/sourcing/search/types/search-gated-user-profile.type';
import { getUserProfileSortedGatedEducation } from '@/core/shared/user-profile/utils';

const props = defineProps<{
  pro: SearchGatedUserProfile;
  isExpanded: boolean;
}>();
const DEFAULT_ITEMS = 1;

const educationToShow = computed(() =>
  props.isExpanded ? educationList.value.length : DEFAULT_ITEMS,
);

const remainingCount = computed(() => educationList.value.length - DEFAULT_ITEMS);

const educationList = computed(() =>
  getUserProfileSortedGatedEducation(props.pro.education ?? []).filter(
    (education) => education.certification,
  ),
);

/**
 * Formats the education date, handling both millisecond and second timestamps
 * If the initial timestamp is invalid or produces a year beyond 2100,
 * it attempts to convert from milliseconds to seconds dividing by 1000
 */
function _formatDate(exp: GatedEducation): string {
  if (!exp.dateIssuedUnixTs) return '';

  // Try with original timestamp
  let date = dayjs.unix(exp.dateIssuedUnixTs);
  // If invalid or year is too far in future, try dividing by 1000
  if (!date.isValid() || date.year() > 2100) {
    date = dayjs.unix(exp.dateIssuedUnixTs / 1000);
  }
  return date.format('MMMM YYYY');
}
</script>
<template>
  <div>
    <div v-if="educationList.length === 0" class="sub-title" data-test-id="no-experience">
      No Education Added
    </div>
    <template v-else>
      <div class="sub-title">
        Education
        <span v-if="!isExpanded && remainingCount > 0" class="counter-text"
          >{{ remainingCount }} more</span
        >
      </div>
      <div
        v-for="(education, i) in educationList.slice(0, educationToShow)"
        :key="i"
        data-test-id="education"
        class="text-xs leading-[18px]"
        :class="{ 'mb-2.5': i !== educationList.length - 1 }"
      >
        <div
          data-test-id="educatopn-certification"
          class="ppc_highlighted_text font-bold text-shade-900"
        >
          {{ education.certification }}
        </div>
        <div data-test-id="education-organization" class="ppc_highlighted_text text-shade-800">
          {{ education.organization }}
        </div>
        <div
          data-test-id="education-date-issued"
          v-if="education.dateIssuedUnixTs"
          class="text-shade-600"
        >
          {{ _formatDate(education) }}
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss"></style>
