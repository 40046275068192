export enum ProUnlockSource {
  PPP = 'ppp',
  APPLICATION = 'application',
  BULK_MESSAGING = 'bulk_messaging',
  PDP = 'pdp',
  PPC = 'ppc',
}

export enum ProUnlockResultStatus {
  SUCCESS = 'success',
  PARTIAL_SUCCESS = 'partial_success',
  ERROR_LIMIT_REACHED = 'error_limit_reached',
}

export interface ProUnlockResult {
  status: ProUnlockResultStatus;
  unlockedProfileIds: Array<number>;
  failedProfileIds: Array<number>;
  message: string;
}
