/* eslint-disable max-len */
import type { ProUnlockSource } from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import { useMessagingStore } from '@/core/sourcing/messaging/messaging.store';
import { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
import { type SelectedGatedUserProfile } from '@/core/sourcing/messaging/types/selected-gated-user-profile.type';
import { getNameForCandidate } from '../search/utils/get-name-for-candidate.util';
import MeService from '@/core/shared/me/me.service';
import type { Job, Project, UserProfile } from '@factoryfixinc/ats-interfaces';
import type { SearchGatedUserProfile } from '../search/types/search-gated-user-profile.type';
import MessagingPersistence from './messaging.persistence';
import SelectedCandidateService from '../selected-candidate/selected-candidate.service';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { InternalError } from '@/core/shared/errors/internal.error';
import { UserOptedOutOfMessagingError } from '@/core/shared/errors/user-opted-out-of-messaging.error';
import { UserAlreadyAppliedError } from '@/core/shared/errors/user-already-applied.error';

export class MessagingService {
  private store = useMessagingStore();
  private persistence = new MessagingPersistence();
  private meService = new MeService();

  get selectedProUserProfiles(): SelectedGatedUserProfile[] {
    return this.store.selectedProUserProfiles;
  }

  set selectedProUserProfiles(value: SelectedGatedUserProfile[]) {
    this.store.selectedProUserProfiles = value;
  }

  get selectedPPCUserProfiles(): SelectedGatedUserProfile[] {
    return this.store.selectedProUserProfiles.filter(
      (profile) => profile.source === MessagingSource.PPC,
    );
  }

  get selectedUserProfilesToUnlock(): SelectedGatedUserProfile[] {
    return this.selectedProUserProfiles.filter((profile) => !profile.unlocked);
  }

  get selectedUserProfilesToUnlockCount(): number {
    return this.selectedUserProfilesToUnlock.length;
  }

  public removeSelectedProUserProfiles(): void {
    this.store.selectedProUserProfiles = [];
  }

  public markSelectedUserProfilesAsUnlocked(userProfileIds: number[]): void {
    this.store.selectedProUserProfiles = this.store.selectedProUserProfiles.map((profile) => {
      if (userProfileIds.includes(profile.id)) {
        profile.unlocked = true;
      }
      return profile;
    });
  }

  public removeProFromSelectedUserProfiles(userProfileId: number): void {
    this.store.selectedProUserProfiles = this.store.selectedProUserProfiles.filter(
      (profile) => profile.id !== userProfileId,
    );
  }

  public removeNonPPCProsFromSelectedUserProfiles(): void {
    this.store.selectedProUserProfiles = this.store.selectedProUserProfiles.filter(
      (profile) => profile.source === MessagingSource.PPC,
    );
  }

  public async addProUserProfileToSelectedList(
    proUserProfile: Pick<
      SearchGatedUserProfile,
      'profileId' | 'nameFirst' | 'nameLast' | 'unlocked' | 'lastActiveTs'
    > & {
      source: MessagingSource;
    },
  ): Promise<void> {
    const proUserProfileId = Number(proUserProfile.profileId);
    const fullName = getNameForCandidate(proUserProfile);
    const existingProfile = this.store.selectedProUserProfiles.find(
      (profile) => profile.id === proUserProfileId,
    );

    if (!existingProfile) {
      this.store.selectedProUserProfiles.push({
        id: proUserProfileId,
        fullName,
        unlocked: proUserProfile.unlocked,
        source: proUserProfile.source,
        lastActiveTs: proUserProfile.lastActiveTs,
        nameFirst: proUserProfile.nameFirst,
        nameLast: proUserProfile.nameLast,
      });
    }
  }

  public setIsTrialUpgradeModalVisible(params: {
    visible: boolean;
    source: ProUnlockSource;
  }): void {
    this.store.isUpgradeModalVisible = params.visible;
    this.store.proUnlockSource = params.source;
  }

  /** @deprecated - use sendMessageToRecipients instead to stop relying on internal state */
  public async sendMessage(message: string, jobId: Job['id']): Promise<void> {
    const employerId = this.meService.employer?.id;

    if (!employerId) throw new Error('Could not send message');

    const promises = this.selectedProUserProfiles.map((profile) => {
      return this.persistence.createMessage(employerId, profile, jobId, message);
    });

    const results = await Promise.allSettled(promises);

    TrackingService.trackAction(TrackingActionName.MESSAGE_SENT, {
      source: this.selectedProUserProfiles?.at(0)?.source,
      num_pros: this.selectedProUserProfiles?.length,
      pro_id: this.selectedProUserProfiles.map((profile) => profile.id),
      conversation_id: this.getFulfilledResults(results).map(
        (result) => result.value.conversationId,
      ),
      message_content: message,
    });

    this.showSnackbarForResults(results);
    const selectedCandidateService = new SelectedCandidateService();
    await selectedCandidateService.refreshCandidate();
    this.removeSelectedProUserProfiles();
  }

  public async sendMessageToRecipients(
    message: string,
    project: Project,
    userProfiles: Array<
      Pick<UserProfile, 'id' | 'nameFirst' | 'nameLast'> & { source?: MessagingSource }
    >,
  ): Promise<void> {
    const employerId = this.meService.employer?.id;

    if (!employerId) {
      throw new Error('Could not send message');
    }

    const promises = userProfiles.map((profile) => {
      return this.persistence.createMessage(employerId, profile, project.jobId, message);
    });

    const results = await Promise.allSettled(promises);

    TrackingService.trackAction(TrackingActionName.MESSAGE_SENT, {
      source: userProfiles?.at(0)?.source,
      num_pros: userProfiles?.length,
      pro_id: userProfiles.map((profile) => profile.id),
      conversation_id: this.getFulfilledResults(results).map(
        (result) => result.value.conversationId,
      ),
      message_content: message,
    });

    this.showSnackbarForResults(results, project);

    const failedResults = this.getRejectedResults(results);
    const errors = this.getInternalErrors(failedResults);
    const otherErrors = this.getOtherErrors(errors);

    if (otherErrors.length) {
      throw new InternalError('One or more unexpected errors occurred.', {
        data: { otherErrors },
      });
    }
  }

  private showSnackbarForResults(
    results: PromiseSettledResult<{ conversationId: number }>[],
    project?: Project,
  ): void {
    const isBulk = results.length > 1;
    const fulfilledResults = this.getFulfilledResults(results);
    const rejectedResults = this.getRejectedResults(results);
    const errors = this.getInternalErrors(rejectedResults);
    const userAlreadyAppliedErrors = this.getUserAlreadyAppliedErrors(errors);
    const userOptedOutOfMessagingErrors = this.getUserOptedOutOfMessagingErrors(errors);
    const otherErrors = this.getOtherErrors(errors);
    const textParts: string[] = [];
    const ATSsyncSuffix = project?.remoteJobId ? ' and synced to your ATS' : '';

    if (fulfilledResults.length) {
      const count = fulfilledResults.length;
      const prefix =
        isBulk && count > 1
          ? `${count} candidates' profiles were added`
          : isBulk && count === 1
            ? `1 candidate's profile was added`
            : `Candidate's profile was added`;
      const suffix = project ? ` to ${project.title} (${project.jobId})` : '';
      const text = `${prefix}${suffix}${ATSsyncSuffix}`;

      textParts.push(text);
    }

    if (userAlreadyAppliedErrors.length) {
      const count = userAlreadyAppliedErrors.length;
      const prefix =
        isBulk && count > 1
          ? `${count} candidates already have an application`
          : isBulk && count === 1
            ? `1 candidate already has an application`
            : 'Candidate already has an application';
      const suffix = project ? ` for ${project.title} (${project.jobId})` : '';
      const text = `${prefix}${suffix}`;

      textParts.push(text);
    }

    if (userOptedOutOfMessagingErrors.length) {
      const count = userOptedOutOfMessagingErrors.length;
      const text =
        isBulk && count > 1
          ? `${count} candidates opted out of messaging`
          : isBulk && count === 1
            ? `1 candidate opted out of messaging`
            : 'Candidate opted out of messaging';

      textParts.push(text);
    }

    if (otherErrors.length) {
      const count = otherErrors.length;
      const text =
        isBulk && count > 1
          ? `${count} candidates failed due to technical issues`
          : isBulk && count === 1
            ? `1 candidate failed due to technical issues`
            : 'Candidate failed due to technical issues';

      textParts.push(text);
    }

    SnackbarService.showSnackbar({
      variant: otherErrors.length ? 'critical' : 'success',
      text: textParts.join(', '),
      appendIcon: 'close',
      horizontalPosition: 'left',
    });
  }

  private getFulfilledResults(
    results: PromiseSettledResult<{ conversationId: number }>[],
  ): PromiseFulfilledResult<{ conversationId: number }>[] {
    return results
      .filter((result) => result.status === 'fulfilled')
      .map((result) => result as PromiseFulfilledResult<{ conversationId: number }>);
  }

  private getRejectedResults(
    results: PromiseSettledResult<{ conversationId: number }>[],
  ): PromiseRejectedResult[] {
    return results
      .filter((result) => result.status === 'rejected')
      .map((result) => result as PromiseRejectedResult);
  }

  private getInternalErrors(rejectedResults: PromiseRejectedResult[]): InternalError[] {
    return rejectedResults
      .filter((result) => result.reason instanceof InternalError)
      .map((result) => result.reason);
  }

  private getUserOptedOutOfMessagingErrors(
    errors: InternalError[],
  ): UserOptedOutOfMessagingError[] {
    return errors.filter(
      (error) => error instanceof UserOptedOutOfMessagingError,
    ) as UserOptedOutOfMessagingError[];
  }

  private getUserAlreadyAppliedErrors(errors: InternalError[]): UserAlreadyAppliedError[] {
    return errors.filter(
      (error) => error instanceof UserAlreadyAppliedError,
    ) as UserAlreadyAppliedError[];
  }

  private getOtherErrors(errors: InternalError[]): InternalError[] {
    return errors
      .filter((error) => !(error instanceof UserOptedOutOfMessagingError))
      .filter((error) => !(error instanceof UserAlreadyAppliedError));
  }
}
