import { defineStore } from 'pinia';
import { reactive, ref } from 'vue';

import { ProjectFilterTab } from '@/core/shared/project/project.type';
import {
  type Pagination,
  ProjectSource,
  ProjectStatus,
  type ProjectWithRelations,
  type SearchProjects,
} from '@factoryfixinc/ats-interfaces';

export const useProjectStore = defineStore(
  'sourcingProject',
  () => {
    // Loading
    const isSearchingProjects = ref<boolean>(false);
    const isSearchingMoreProjects = ref<boolean>(false);

    // Data
    const projects = ref<ProjectWithRelations<'candidates'>[]>([]);
    const currentProject = ref<ProjectWithRelations<'candidates'>>();
    const employerLocations = ref<string[]>();
    const didSelectUserInProjectFilters = ref<boolean>(false);
    const didLoadProjects = ref<boolean>(false);
    const hasProjects = ref<boolean>(false);
    const isProjectFromDeepLinkRemoved = ref<boolean>(false);
    const projectTitlesByJobId = ref<Map<number, string>>(new Map());
    const lastSelectedSourcingProjectId = ref<number>();
    const selectedTab = ref<ProjectFilterTab>(ProjectFilterTab.MY_JOBS);

    // Search params
    const title = ref<string>('');
    const titleOrReqId = ref<string>('');
    const source = ref<ProjectSource | null>(ProjectSource.ALL);
    const copilot = ref<boolean | null>(null);
    const status = ref<ProjectStatus | null>(ProjectStatus.LIVE);
    const locations = ref<string[]>([]);
    const teamIds = ref<number[]>([]);
    const searchParams = ref<Partial<SearchProjects>>({});
    const pagination = reactive<Pagination>({
      page: 1,
      itemsPerPage: 25,
    });

    return {
      projects,
      currentProject,
      employerLocations,
      title,
      titleOrReqId,
      source,
      copilot,
      status,
      locations,
      teamIds,
      pagination,
      isSearchingProjects,
      isSearchingMoreProjects,
      searchParams,
      didSelectUserInProjectFilters,
      didLoadProjects,
      hasProjects,
      isProjectFromDeepLinkRemoved,
      projectTitlesByJobId,
      lastSelectedSourcingProjectId,
      selectedTab,
    };
  },
  {
    persist: false,
  },
);
