<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { type LocationQuery, useRoute, useRouter } from 'vue-router';
import { debounce } from 'radash';
import ProjectSearchFiltersDialog from '@/components/Shared/Projects/ProjectSearchFiltersDialog.vue';
import FilterLinesSvg from '@/assets/svg/filter-lines-20.svg?component';
import { ProjectSource, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import MeService from '@/core/shared/me/me.service';
import ProjectService from '@/core/shared/project/project.service';
import { ProjectFilterTab } from '@/core/shared/project/project.type';

const emit = defineEmits<{
  (e: 'change:filters'): void;
}>();

const meService = new MeService();
const projectService = new ProjectService();
const route = useRoute();
const router = useRouter();
const isProjectSearchFiltersDialogOpen = ref<boolean>(false);
const searchQuery = computed(() => route.query);
const filtersCount = computed(() => projectService.filtersCount);
const titleOrReqIdInput = ref<HTMLInputElement | null>(null);

async function setTitleOrReqIdQuery() {
  const searchText = titleOrReqIdInput.value;
  const query = { ...searchQuery.value };

  if (searchText?.value) {
    query.titleOrReqId = searchText.value;
  } else {
    delete query.titleOrReqId;
  }
  // Since we moved the toggle here, we need to save the copilot value to the query
  // or remove it if it's not selected
  if (projectService.copilot) {
    query.copilot = String(projectService.copilot);
  } else {
    delete query.copilot;
  }

  if (projectService.locations?.length) {
    query.locations = projectService.locations;
  }
  if (projectService.status) {
    query.status = projectService.status;
  }
  if (projectService.teamIds?.length) {
    query.teamIds = projectService.teamIds.map(String);
  } else {
    delete query.teamIds;
  }
  if (projectService.source) {
    query.source = projectService.source;
  }

  await router.push({ query });
}

function openProjectSearchFiltersDialog() {
  isProjectSearchFiltersDialogOpen.value = true;
}

/**
 * Sets the project filters based on the query from the URL
 * @param storageQueryData if provided, will use this data to set the project filters instead of the query from the URL
 */
async function setProjectFilters(localStorageQuery?: LocationQuery | null) {
  const query = localStorageQuery ? { ...localStorageQuery } : { ...searchQuery.value };

  // Title or Req ID
  if (query.titleOrReqId) {
    projectService.titleOrReqId = query.titleOrReqId as string;
  }

  // Copilot
  if (query.copilot) {
    // Need to be sure this its a string: query params is a string but localStorage is a boolean
    if (String(query.copilot) === 'true') {
      projectService.copilot = true;
    } else {
      projectService.copilot = null;
    }
  }
  // Source
  if (query.source) {
    projectService.source = query.source as ProjectSource;
  }

  // Location
  if (query.location) {
    if (typeof query.location === 'string') {
      projectService.locations = [query.location];
    }

    if (Array.isArray(query.location)) {
      projectService.locations = query.location as string[];
    }
  }
  // Locations
  if (query.locations) {
    if (typeof query.locations === 'string') {
      projectService.locations = [query.locations];
    }

    if (Array.isArray(query.locations)) {
      projectService.locations = query.locations as string[];
    }
  }
  // Status
  if (query.status) {
    if (query.status === ProjectStatus.LIVE) {
      projectService.status = ProjectStatus.LIVE;
    } else {
      projectService.status = null;
    }
  }
  // Team Ids
  if (query.teamIds) {
    if (typeof query.teamIds === 'string') {
      projectService.teamIds = [Number(query.teamIds)];
    }

    if (Array.isArray(query.teamIds)) {
      projectService.teamIds = query.teamIds.map(Number);
    }
    projectService.didSelectUserInProjectFilters = true;
  } else {
    selectUserInProjectFilters();
  }

  const queryToSave: Pick<
    ProjectService,
    'copilot' | 'locations' | 'status' | 'source' | 'teamIds'
  > = {
    copilot: projectService.copilot,
    locations: projectService.locations,
    // We need to save status as REMOVED because the `null` value skips and assigns `LIVE` as default
    status: projectService.status ? projectService.status : ProjectStatus.REMOVED,
    source: projectService.source,
    teamIds: projectService.teamIds,
  };
  // Save queryToSave to localStorage to persist filters
  localStorage.setItem(
    `search-query-${currentUserAndEmployerId.value}`,
    JSON.stringify(queryToSave),
  );

  // Update the selected tab `All Jobs` or `My Jobs` based on team filter selection
  projectService.updateSelectedTabBasedOnTeamFilter();

  emit('change:filters');
}

function selectMyJobs() {
  projectService.selectedTab = ProjectFilterTab.MY_JOBS;
  // Set the current user as the only team member
  const ownUserProfileId = meService.userProfile?.id as number;
  projectService.teamIds = [ownUserProfileId];
  // To update the query with the new team filter
  setTitleOrReqIdQuery();
  emit('change:filters');
}

function selectAllJobs() {
  projectService.selectedTab = ProjectFilterTab.ALL_JOBS;
  // Clear team filter when switching to All Jobs
  projectService.teamIds = [];
  // To update the query with the new team filter
  setTitleOrReqIdQuery();
  emit('change:filters');
}

function toggleCopilotFilter() {
  projectService.copilot = projectService.copilot === true ? false : true;
  setTitleOrReqIdQuery();
  emit('change:filters');
}

function selectUserInProjectFilters() {
  if (projectService.didSelectUserInProjectFilters) {
    return;
  }

  projectService.didSelectUserInProjectFilters = true;
  const isOwnUserProfileInTeamList = meService.employerUsers?.some(
    (user) => user.id === meService.userProfile?.id,
  );

  if (!isOwnUserProfileInTeamList) {
    return;
  }
  const ownUserProfileId = meService.userProfile?.id as number;
  projectService.teamIds = [ownUserProfileId];
  projectService.selectedTab = ProjectFilterTab.MY_JOBS;
}

const debouncedSetTitleOrReqIdQuery = debounce({ delay: 500 }, setTitleOrReqIdQuery);

watch(searchQuery, async () => {
  await setProjectFilters();
});

onMounted(async () => {
  try {
    await Promise.all([projectService.getEmployerLocations()]);
    await setProjectFilters(getLocalStorageQuery());
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load filters');
  }
});

function getLocalStorageQuery() {
  const queryLocalStorage = localStorage.getItem(`search-query-${currentUserAndEmployerId.value}`);
  return queryLocalStorage ? JSON.parse(queryLocalStorage) : null;
}
const currentUserAndEmployerId = computed(
  () => `${meService.userProfile?.id}-${meService.employer?.id}`,
);
</script>

<template>
  <div>
    <!-- Title Input -->
    <div
      class="relative flex h-[40px] items-center rounded-md bg-shade-840 p-2"
      data-test-id="project-filter-section"
    >
      <input
        ref="titleOrReqIdInput"
        v-model="projectService.titleOrReqId"
        type="text"
        placeholder="Search by job name or ATS Req ID"
        class="flex-1 bg-shade-840 text-sm focus:outline-none"
        @input="debouncedSetTitleOrReqIdQuery"
        data-test-id="project-search-text-filter"
      />
    </div>

    <!-- Tab Selection: My Jobs / All Jobs -->
    <div class="mt-2 flex rounded-md bg-shade-840 p-0.5">
      <button
        class="tab-filter-button"
        :class="{
          selected: projectService.selectedTab === ProjectFilterTab.MY_JOBS,
        }"
        @click="selectMyJobs"
      >
        My jobs
      </button>
      <button
        class="tab-filter-button"
        :class="{
          selected: projectService.selectedTab === ProjectFilterTab.ALL_JOBS,
        }"
        @click="selectAllJobs"
      >
        All jobs
      </button>
    </div>

    <div class="mt-2 flex justify-between">
      <!-- Filters Menu Button -->
      <button
        class="inline-flex items-center rounded-md px-2 py-1 transition-colors duration-300 hover:bg-shade-840"
        @click="openProjectSearchFiltersDialog"
      >
        <!-- Label -->
        <div class="text-xs font-semibold leading-4">Filters</div>
        <!-- Icon w/ Count -->
        <div class="relative ml-1 cursor-pointer">
          <FilterLinesSvg class="stroke-white" />
          <div
            v-if="filtersCount"
            class="absolute right-[-4px] top-[-2px] h-[12px] w-[12px] rounded-full bg-highlight-600"
          >
            <div class="flex items-center justify-center">
              <span class="m-[-1.5px] text-2xs font-bold">{{ filtersCount }}</span>
            </div>
          </div>
        </div>
      </button>
      <!-- Copilot Filter Toggle -->
      <div class="mb-1.5 mt-2 flex items-center">
        <label class="flex cursor-pointer items-center">
          <span class="mr-3.5 text-xs">Show Copilot jobs only</span>
          <v-switch
            v-model:model-value="projectService.copilot"
            color="highlight-500"
            class="mr-2 !inline-block"
            hide-details
            density="compact"
            @click="toggleCopilotFilter"
          />
        </label>
      </div>
    </div>

    <ProjectSearchFiltersDialog v-model="isProjectSearchFiltersDialogOpen" />
  </div>
</template>
<style lang="postcss" scoped>
.tab-filter-button {
  @apply flex-1 rounded-md py-2.5 text-xs leading-4 text-tint-200;
  @apply transition-all duration-300;
  &:hover {
    @apply bg-shade-820 text-tint-0;
  }
  &.selected {
    @apply bg-tint-0 font-semibold text-shade-900;
  }
}
:deep(.v-switch) {
  @apply mt-[-20px] h-5;
  &__track {
    opacity: 1;
    @apply bg-tint-400;
  }
  &__track.bg-highlight-500 {
    opacity: 1;
  }
  &__thumb {
    @apply border border-tint-400 !bg-white;
  }
}
</style>
