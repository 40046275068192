<script setup lang="ts">
import { computed } from 'vue';
import searchEmptyStateImage from '@/assets/svg/suitecase-circke-empty-state.svg?component';
import PlusSvg from '@/assets/svg/plus-16.svg?component';

const props = defineProps<{
  type: 'no-results' | 'empty-my-jobs';
}>();

defineEmits<{
  (e: 'add-job'): void;
}>();

const title = computed(() => {
  if (props.type === 'no-results') {
    return 'No matching jobs';
  } else {
    return 'No saved jobs... yet!';
  }
});

const description = computed(() => {
  if (props.type === 'no-results') {
    return 'Try adjusting your filters or create a new job to put Copilot to work finding qualified candidates';
  } else {
    return "Add a new job or head over to the All jobs tab to review your team's jobs";
  }
});
</script>

<template>
  <div class="mx-auto flex h-full max-w-[240px] flex-col items-center justify-center text-center">
    <div class="mx-auto flex h-[128px] w-[128px] items-center rounded-full bg-shade-840">
      <searchEmptyStateImage alt="Not found" class="mx-auto h-[64px] w-[64px] text-shade-800" />
    </div>

    <h3 class="mt-4 text-sm font-bold text-tint-200">{{ title }}</h3>
    <p class="mt-2 text-sm text-shade-800">{{ description }}</p>
    <v-btn
      v-if="type === 'empty-my-jobs'"
      class="mt-4 text-sm font-semibold !normal-case !leading-5 !tracking-normal text-primary-300"
      variant="text"
      :ripple="false"
      @click="$emit('add-job')"
    >
      <PlusSvg class="mr-1" /> Add a new job
    </v-btn>
  </div>
</template>
