import { DEFAULT_ITEMS_PER_PAGE } from '@/core/sourcing/search/consts/default-pagination.const';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useSearchStore = defineStore(
  'sharedSearch',
  () => {
    // Loading
    const isSearchingCandidates = ref<boolean>(false);

    // Form Data
    const keyword = ref<string | undefined>(undefined);
    const jobTitle = ref<string | undefined>(undefined);
    const skills = ref<string | undefined>(undefined);
    const machines = ref<string | undefined>(undefined);
    const brands = ref<string | undefined>(undefined);
    const lastActive = ref<number | undefined>(undefined);
    const location = ref<string | undefined>(undefined);
    const distance = ref<number | undefined>(undefined);
    const page = ref<number | undefined>(1);
    const itemsPerPage = ref<number | undefined>(DEFAULT_ITEMS_PER_PAGE);

    // Layout
    const candidateListScrollTop = ref<number>(0);
    const alreadyExecutedASearch = ref<boolean>(false);

    return {
      isSearchingCandidates,
      brands,
      jobTitle,
      lastActive,
      keyword,
      machines,
      skills,
      location,
      distance,
      page,
      itemsPerPage,
      candidateListScrollTop,
      alreadyExecutedASearch,
    };
  },
  {
    persist: {
      omit: ['alreadyExecutedASearch'],
    },
  },
);
