import { SearchPersistence } from '@/core/shared/search/search.persistence';
import type { PlacePrediction } from '@/core/shared/search/types/place-prediction.type';
import { useSearchStore } from './search.store';
import { SelectedFilterType } from '@/core/sourcing/search/types/selected-filter-type.type';
import type { SelectedFilter } from '@/core/sourcing/search/types/selected-filter.type';
import { SearchService as SourcingSearchService } from '@/core/sourcing/search/search.service';
import { ErrorService } from '../errors/error.service';
import { useMeStore } from '../me/me.store';
import JobApplicantService from '../job-applicant/job-applicant.service';
import type { LocationQuery } from 'vue-router';
import { ViewedCandidateService } from '@/core/sourcing/viewed-candidates/viewed-candidate.service';

export class SearchService {
  private persistence = new SearchPersistence();
  private store = useSearchStore();

  public async getAddressPredictions(address: string): Promise<PlacePrediction[]> {
    return this.persistence.getAddressPredictions(address);
  }

  public get keyword(): string | undefined {
    return this.store.keyword;
  }

  public set keyword(keyword: string | undefined) {
    this.store.keyword = keyword;
  }

  public get jobTitle(): string | undefined {
    return this.store.jobTitle;
  }

  public set jobTitle(jobTitle: string | undefined) {
    this.store.jobTitle = jobTitle;
  }

  public get skills(): string | undefined {
    return this.store.skills;
  }

  public set skills(skills: string | undefined) {
    this.store.skills = skills;
  }

  public get machines(): string | undefined {
    return this.store.machines;
  }

  public set machines(machines: string | undefined) {
    this.store.machines = machines;
  }

  public get brands(): string | undefined {
    return this.store.brands;
  }

  public set brands(brands: string | undefined) {
    this.store.brands = brands;
  }

  public get lastActive(): number | undefined {
    return this.store.lastActive;
  }

  public set lastActive(lastActive: number | undefined) {
    this.store.lastActive = lastActive;
  }

  public get location(): string | undefined {
    return this.store.location;
  }

  public set location(location: string | undefined) {
    this.store.location = location;
  }

  public get distance(): number | undefined {
    return this.store.distance;
  }

  public set distance(distance: number | undefined) {
    this.store.distance = distance;
  }

  public set page(page: number | undefined) {
    this.store.page = page;
  }

  public get page(): number | undefined {
    return this.store.page;
  }

  public get itemsPerPage(): number | undefined {
    return this.store.itemsPerPage;
  }

  public set itemsPerPage(itemsPerPage: number | undefined) {
    this.store.itemsPerPage = itemsPerPage;
  }

  public get isSearchingCandidates(): boolean {
    return this.store.isSearchingCandidates;
  }

  public set isSearchingCandidates(isSearchingCandidates: boolean) {
    this.store.isSearchingCandidates = isSearchingCandidates;
  }

  public get candidateListScrollTop(): number {
    return this.store.candidateListScrollTop;
  }

  public set candidateListScrollTop(candidateListScrollTop: number) {
    this.store.candidateListScrollTop = candidateListScrollTop;
  }

  public get alreadyExecutedASearch(): boolean {
    return this.store.alreadyExecutedASearch;
  }

  public set alreadyExecutedASearch(alreadyExecutedASearch: boolean) {
    this.store.alreadyExecutedASearch = alreadyExecutedASearch;
  }

  /**
   * Syncs the current filters to the sourcing search service
   * @param searchService The sourcing search service instance to sync filters to
   */
  public syncFiltersToSourcingSearch(searchService: SourcingSearchService): void {
    // Clear existing filters
    searchService.selectedFilters = [];

    // Add filters with proper typing for each type
    if (this.keyword) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.KEYWORD,
        value: this.keyword,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.jobTitle) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.JOB_TITLE,
        value: this.jobTitle,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.skills) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.KNOWLEDGE_DISCIPLINE,
        value: this.skills,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.machines) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.TECHNOLOGY,
        value: this.machines,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.brands) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.BRAND,
        value: this.brands,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.lastActive) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.LAST_ACTIVE,
        value: this.lastActive,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.location) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.LOCATION,
        value: this.location,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.distance) {
      const filter: SelectedFilter = {
        type: SelectedFilterType.AROUND_RADIUS,
        value: this.distance,
      };
      searchService.selectedFilters.push(filter);
    }

    if (this.page) {
      searchService.pagination.page = this.page;
    }

    if (this.itemsPerPage) {
      searchService.pagination.itemsPerPage = this.itemsPerPage;
    }
  }

  public setSelectedFiltersFromUrlQuery(urlQuery: Record<string, string | null>): void {
    this.keyword = urlQuery.keyword || undefined;
    this.location = urlQuery.location || undefined;
    this.distance = urlQuery.distance ? Number(urlQuery.distance) : undefined;
    this.brands = urlQuery.brands || undefined;
    this.jobTitle = urlQuery.jobTitle || undefined;
    this.skills = urlQuery.skills || undefined;
    this.machines = urlQuery.machines || undefined;
    this.lastActive = urlQuery.lastActive ? Number(urlQuery.lastActive) : undefined;
    this.page = urlQuery.page ? Number(urlQuery.page) : undefined;
    this.itemsPerPage = urlQuery.itemsPerPage ? Number(urlQuery.itemsPerPage) : undefined;
  }

  public getUrlQueryFromSelectedFilters(): Record<string, string | null> {
    const urlQuery: Record<string, string | null> = {};

    if (this.keyword) {
      urlQuery.keyword = this.keyword;
    }

    if (this.location) {
      urlQuery.location = this.location;
    }

    if (this.distance) {
      urlQuery.distance = `${this.distance}`;
    }

    if (this.brands) {
      urlQuery.brands = this.brands;
    }

    if (this.jobTitle) {
      urlQuery.jobTitle = this.jobTitle;
    }

    if (this.skills) {
      urlQuery.skills = this.skills;
    }

    if (this.machines) {
      urlQuery.machines = this.machines;
    }

    if (this.lastActive) {
      urlQuery.lastActive = `${this.lastActive}`;
    }

    if (this.page) {
      urlQuery.page = `${this.page}`;
    }

    if (this.itemsPerPage) {
      urlQuery.itemsPerPage = `${this.itemsPerPage}`;
    }

    return urlQuery;
  }

  /**
   * Checks if there are any filters set
   */
  public hasFilters(): boolean {
    return !!(
      this.keyword ||
      this.jobTitle ||
      this.skills ||
      this.machines ||
      this.brands ||
      this.lastActive ||
      this.location
    );
  }

  public urlQueryHasFilters(urlQuery: Record<string, string | null>): boolean {
    return !!(
      urlQuery.keyword ||
      urlQuery.location ||
      urlQuery.distance ||
      urlQuery.brands ||
      urlQuery.jobTitle ||
      urlQuery.skills ||
      urlQuery.machines ||
      urlQuery.lastActive
    );
  }

  public async resolveUrlQuery(
    urlQuery: LocationQuery | LocationQuery[],
    options?: { localStateIsPriority: boolean },
  ): Promise<Record<string, string | null> | undefined> {
    try {
      if (Array.isArray(urlQuery)) {
        return;
      }

      const validUrlQuery = urlQuery as unknown as Record<string, string | null>;
      const hasUrlQueryFilters = this.urlQueryHasFilters(validUrlQuery);
      let newUrlQuery = this.getUrlQueryFromSelectedFilters();

      if (newUrlQuery && options?.localStateIsPriority) {
        this.setSelectedFiltersFromUrlQuery(newUrlQuery);
      } else if (hasUrlQueryFilters) {
        this.setSelectedFiltersFromUrlQuery(validUrlQuery);
        newUrlQuery = validUrlQuery;
      }

      return newUrlQuery;
    } catch (error) {
      ErrorService.captureException(error);
    }
  }

  public async searchCandidatesWithAdditionalInformation(options?: {
    page?: number;
    itemsPerPage?: number;
  }): Promise<void> {
    const meStore = useMeStore();
    const sourcingSearchService = new SourcingSearchService();
    const jobApplicantService = new JobApplicantService();
    const viewedCandidateService = new ViewedCandidateService();
    const employerId = meStore.employer?.id as unknown as number;

    if (options?.page) {
      this.page = options.page;
    }

    if (options?.itemsPerPage) {
      this.itemsPerPage = options.itemsPerPage;
    }

    this.syncFiltersToSourcingSearch(sourcingSearchService);

    try {
      viewedCandidateService.viewedCandidates = [];

      const result = await sourcingSearchService.searchCandidates();
      const userProfileIds = result.hits.map((candidate) => Number(candidate.profileId));

      await Promise.all([
        // Fetch job applicants for the ProPreviewCard
        jobApplicantService.getJobApplicantsByUserProfileIds(employerId, userProfileIds),
        viewedCandidateService.fetchViewedCandidates(),
      ]);
    } catch (error) {
      sourcingSearchService.candidates = []; // Reset the candidates only if search fails
      throw error; // Still need to throw the error the calling function will handle it
    } finally {
      this.alreadyExecutedASearch = true;
    }
  }
}
