import type { JobApplicant, JobApplicantStatusHistory } from '@factoryfixinc/ats-interfaces';
import { useFFRestApi } from '@/composables/useApi';
import { InternalError } from '@/core/shared/errors/internal.error';
import type { CustomJobApplicant } from '@/utils/export-xlsx-utils';
import { useConversationMessageStore } from '@/core/conversations/message/conversation-message.store';
import { ErrorName } from '../errors/enums/error-name.enum';

type LocalFilter = {
  where?: Record<string, unknown>;
  order?: string;
  include?: Array<{
    relation: string;
    scope?: Record<string, unknown>;
  }>;
};

export class JobApplicantPersistence {
  private readonly messageStore = useConversationMessageStore();

  async update(
    applicationId: number,
    application: Partial<JobApplicant>,
    isSilent: boolean,
  ): Promise<void> {
    const { error } = await useFFRestApi(
      `/job-applicant/${applicationId}?silent=${isSilent}`,
    ).patch(application);

    if (error.value) {
      throw new InternalError('Could not update job application', {
        error: error.value,
        data: { applicationId, application },
      });
    }
  }
  async get(applicationId: number): Promise<JobApplicant> {
    const { error, data } = await useFFRestApi(`/job-applicant/${applicationId}`)
      .get()
      .json<JobApplicant>();
    if (error.value) {
      throw new InternalError('Failed to fetch job application', {
        error: error.value,
        data: { applicationId },
      });
    } else {
      return data.value as JobApplicant;
    }
  }
  async getApplicantsByJobId(
    jobId: number,
    filter: string | undefined,
  ): Promise<CustomJobApplicant[]> {
    const { error, data } = await useFFRestApi(
      `/job/${jobId}/job-applicant${filter ? '?filter=' + encodeURIComponent(filter) : ''}`,
    )
      .get()
      .json<CustomJobApplicant[]>();
    if (error.value) {
      throw new InternalError('Failed to fetch job application', {
        error: error.value,
        data: { jobId, filter },
      });
    } else {
      return data.value as CustomJobApplicant[];
    }
  }
  async getApplicantsStatusHistory(applicationId: number): Promise<JobApplicantStatusHistory[]> {
    const callerName = this.getApplicantsStatusHistory.name;
    const concurrentCalls = this.messageStore.cancellableRequests.filter(
      (req) => req.caller === callerName,
    );

    if (concurrentCalls.length) {
      await Promise.all(concurrentCalls.map((req) => req.abort()));
      this.messageStore.cancellableRequests = this.messageStore.cancellableRequests.filter(
        (req) => req.caller !== callerName,
      );
    }

    const { error, data, statusCode, execute, abort } = await useFFRestApi(
      `/job-applicant/${applicationId}/status-history`,
    )
      .get()
      .json<JobApplicantStatusHistory[]>();

    this.messageStore.cancellableRequests.push({
      abort,
      caller: callerName,
    });
    await execute();

    if (error.value) {
      if (error.value.name === ErrorName.ABORT_ERROR) {
        return [];
      }
      throw new InternalError('Failed to fetch job applicant status history', {
        error: error.value,
        status: statusCode.value,
        data: { applicationId },
      });
    }

    return data.value ?? [];
  }

  async getJobApplicantsByUserProfileIds(
    employerId: number,
    userProfileIds: number[],
  ): Promise<JobApplicant[]> {
    const filter: LocalFilter = {
      order: 'applyTs desc',
      where: { userProfileId: { inq: [...userProfileIds] } },
      include: [
        {
          relation: 'job',
        },
        {
          relation: 'userProfile',
        },
      ],
    };

    const encodedFilter = encodeURIComponent(JSON.stringify(filter));
    const path = `/employer/${employerId}/job-applicant?filter=${encodedFilter}`;

    const { data: jobApplicants, error } = await useFFRestApi(path).get().json<JobApplicant[]>();

    if (error?.value || !jobApplicants.value) {
      throw new InternalError('Failed to fetch job applicants', {
        error: error?.value,
        data: { employerId, userProfileIds },
      });
    }
    return jobApplicants.value;
  }
}
