<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import Autocomplete from '@/components/Shared/Input/Autocomplete/Autocomplete.vue';
import { debounce } from 'radash';
import { SearchService } from '@/core/sourcing/search/search.service';
import type { PlacePrediction } from '@/core/sourcing/search/types/place-prediction.type';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { CustomerSuccessService } from '@/core/customer-success/customer-success.service';
import BaseModal from '@/components/Shared/Modals/BaseModal.vue';
import { isRequired } from '@/utils/forms';
import { ErrorService } from '@/core/shared/errors/error.service';
import CopySvg from '@/assets/svg/conversations/copy-01.svg?component';

const dialogService = new DialogService();
const searchService = new SearchService();
const customerSuccessService = new CustomerSuccessService();

const isOpen = computed(() => dialogService.isLaborMarketReportsModalOpen);

// Success modal state
const showSuccessModal = ref(false);
const successReferenceId = ref('');
const successMessage = ref('');
const isCopied = ref(false);
const isSubmitting = ref(false);

// Form data
const jobTitle = ref('');
const location = ref<string | undefined>(undefined);
const jobTitleInput = ref<HTMLInputElement | null>(null);
const defaultDistance = 30;
const distance = ref(defaultDistance);

// Distance dropdown options
const distanceItems = [
  { id: 10, title: '10 miles' },
  { id: 20, title: '20 miles' },
  { id: 30, title: '30 miles' },
  { id: 40, title: '40 miles' },
  { id: 50, title: '50 miles' },
  { id: 60, title: '60 miles' },
  { id: 70, title: '70 miles' },
  { id: 80, title: '80 miles' },
  { id: 90, title: '90 miles' },
  { id: 100, title: '100+ miles' },
];

// Location autocomplete
const searchLocationText = ref<string>('');
const isLoadingLocations = ref(false);
const locationItems = ref<PlacePrediction[]>([]);

// Map location items to match the autocomplete structure
const listOfLocations = computed(() =>
  locationItems.value.map((item) => ({ title: item.value, id: item.id })),
);

// Debounced function to fetch address predictions
const populateAddressPredictions = debounce({ delay: 300 }, async (address: string) => {
  isLoadingLocations.value = true;
  locationItems.value = await searchService.getAddressPredictions(address);
  isLoadingLocations.value = false;
});

// Handle location search
async function onSearchLocation(value: string) {
  searchLocationText.value = value;
  if (!value || typeof value !== 'string') {
    return;
  }
  await populateAddressPredictions(value);
}

// Location focus state
const isLocationFocused = ref(false);
function onLocationFocused(value: boolean) {
  isLocationFocused.value = value;
}

// Computed properties for no data text
const noDataText = computed(() =>
  searchLocationText.value.trim() === '' ? 'Type to search' : 'No results',
);

const formIsValid = computed(() => {
  return !!jobTitle.value && !!location.value && !!distance.value;
});

// Reset form when modal opens
watch(isOpen, (open) => {
  if (open) {
    resetForm();
    // Focus the job title input after a short delay to ensure the modal is fully rendered
    setTimeout(() => {
      if (jobTitleInput.value) {
        jobTitleInput.value.focus();
      }
    }, 100);
  }
});

// Form submission
async function handleSubmit() {
  isSubmitting.value = true;
  try {
    // Call the API to submit the labor market report request
    const response = await customerSuccessService.submitLaborMarketReportRequest(
      jobTitle.value,
      location.value,
      distance.value,
    );

    if (response && response.success) {
      // Store success information for the modal
      successReferenceId.value = response.referenceId;
      successMessage.value = response.message;

      // Close the request modal and show the success modal
      dialogService.isLaborMarketReportsModalOpen = false;
      showSuccessModal.value = true;

      // Reset form
      resetForm();
    } else {
      // Handle error case
      SnackbarService.critical(response?.message || 'Failed to submit request. Please try again.');
    }
  } catch (error) {
    // Handle unexpected errors
    SnackbarService.critical('An unexpected error occurred. Please try again.');
  } finally {
    isSubmitting.value = false;
  }
}

// Close success modal
function closeSuccessModal() {
  showSuccessModal.value = false;
}

// Form cancellation
function handleCancel() {
  resetForm();
  dialogService.isLaborMarketReportsModalOpen = false;
}

// Reset form fields
function resetForm() {
  jobTitle.value = '';
  location.value = undefined;
  distance.value = defaultDistance;
}

// Copy reference ID to clipboard
function copyReferenceId() {
  if (successReferenceId.value) {
    navigator.clipboard
      .writeText(successReferenceId.value)
      .then(() => {
        isCopied.value = true;
        setTimeout(() => {
          isCopied.value = false;
        }, 1500); // Match ProHeaderTooltip's timeout
      })
      .catch((error) => {
        ErrorService.captureException(error);
        SnackbarService.critical('Failed to copy reference ID');
      });
  }
}
</script>

<template>
  <v-dialog :model-value="isOpen" max-width="600px" height="550px" persistent>
    <div class="rounded-2xl bg-white p-8">
      <!-- Header -->
      <div class="mb-6">
        <img
          class="float-right mt-1 cursor-pointer"
          src="@/assets/svg/close-black.svg"
          alt="close"
          width="20"
          height="20"
          @click="handleCancel"
        />
        <p class="font-serif text-2xl font-black text-copilot">Request a labor market report</p>
        <p class="font-sans font-normal text-shade-800">
          We'll email you a custom report based on your criteria
        </p>
      </div>

      <!-- Form -->
      <form @submit.prevent="handleSubmit" class="flex flex-col gap-4">
        <!-- Job Title field (required) -->
        <div>
          <label for="jobTitle" class="mb-1 block text-sm font-medium text-shade-900">
            Job title<span class="text-red-500">*</span>
          </label>
          <input
            id="jobTitle"
            v-model="jobTitle"
            type="text"
            ref="jobTitleInput"
            class="w-full rounded-md border border-tint-80 px-3 py-2 text-sm text-shade-900 placeholder:text-shade-800 focus:outline-none focus:ring-2 focus:ring-highlight-500"
            :rules="[isRequired]"
            placeholder="Enter job title"
          />
        </div>

        <!-- Location field with autocomplete -->
        <div>
          <label for="location" class="mb-1 block text-sm font-medium text-shade-900">
            Location<span class="text-red-500">*</span>
          </label>
          <autocomplete
            v-model="location"
            placeholder="Enter a location"
            :items="listOfLocations"
            :loading="isLoadingLocations"
            @update:search="onSearchLocation"
            @update:focused="onLocationFocused"
            :no-data-text="noDataText"
            :rules="[isRequired]"
            open-text="Type to search"
            clearable
            plain
          />
        </div>

        <!-- Distance field -->
        <div>
          <label for="distance" class="mb-1 block text-sm font-medium text-shade-900">
            Distance<span class="text-red-500">*</span>
          </label>
          <autocomplete
            v-model="distance"
            :items="distanceItems"
            item-title="title"
            item-value="id"
            :rules="[isRequired]"
            plain
          />
        </div>

        <!-- Actions -->
        <div class="mt-4 flex items-center justify-end">
          <v-btn
            :ripple="false"
            class="modal-button-text mr-4"
            variant="flat"
            @click.prevent="handleCancel"
            >Cancel</v-btn
          >
          <v-btn
            :ripple="false"
            class="modal-button-primary"
            variant="flat"
            type="submit"
            :disabled="!formIsValid || isSubmitting"
            :loading="isSubmitting"
            @click.prevent="handleSubmit"
          >
            Request report
          </v-btn>
        </div>
      </form>
    </div>
  </v-dialog>

  <!-- Success Modal -->
  <BaseModal
    :model-value="showSuccessModal"
    :show-heading="false"
    :show-cancel-button="false"
    submit-button-text="Close"
    @submit="closeSuccessModal"
    @cancel="closeSuccessModal"
    :modal-class="'rounded-lg'"
  >
    <!-- Custom header with centered title -->
    <template #header>
      <div>
        <div class="text-center font-serif text-2xl font-black text-copilot">
          Thank you for your request
        </div>
      </div>
    </template>

    <div class="mb-6 mt-4 flex flex-col items-center">
      <p class="mb-4 text-center text-base text-shade-800">
        Your labor market report is being generated and will be emailed to you shortly. Please keep
        your reference ID for future inquiries.
      </p>
      <div class="mt-2 rounded-lg bg-tint-40 px-4 py-4">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-2">
            <span class="gray-600 text-sm">
              Reference ID: <span class="font-bold">{{ successReferenceId }}</span>
            </span>
            <button
              class="relative ml-2 flex items-center justify-center rounded-full p-1 transition-colors"
              @click="copyReferenceId"
            >
              <CopySvg class="h-4 w-4 cursor-pointer text-shade-800 hover:text-shade-900" />
              <transition name="fade">
                <div
                  v-if="isCopied"
                  class="absolute -top-8 left-1/2 z-50 -translate-x-1/2 rounded bg-black px-2 py-1 text-xs text-white"
                >
                  Copied!
                </div>
              </transition>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Custom actions slot with centered button -->
    <template #actions>
      <div class="flex justify-center">
        <button
          class="rounded-lg bg-copilot px-6 py-2 text-sm font-medium text-white hover:bg-copilot/90"
          @click="closeSuccessModal"
        >
          Close
        </button>
      </div>
    </template>
  </BaseModal>
</template>

<style scoped>
.modal-button-text {
  @apply !h-auto !bg-transparent !px-4 !py-2 !text-sm !font-bold;
  @apply !normal-case !text-shade-800 !no-underline;
}

.modal-button-primary {
  @apply !h-auto !bg-shade-880 !px-4 !py-2 !text-sm !font-semibold;
  @apply !normal-case !text-white !no-underline;
  @apply !transition-colors !duration-300;
}

.modal-button-primary:disabled {
  @apply pointer-events-none !bg-tint-80 !text-tint-400;
}

/* Make autocomplete placeholder consistent with regular inputs */
:deep(.v-field__input input::placeholder) {
  font-size: inherit !important;
  opacity: 1 !important;
}

/* Ensure location field placeholder is visible */
:deep(.plain-autocomplete .v-field__input input::placeholder) {
  color: #64748b !important; /* shade-800 */
  opacity: 1 !important;
}

/* Add fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
