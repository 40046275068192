import {
  CustomerSuccessPersistence,
  type LaborMarketReportRequestInput,
  type LaborMarketReportRequestOutput,
} from './customer-success.persistence';

export class CustomerSuccessService {
  private persistence = new CustomerSuccessPersistence();

  /**
   * Submits a request for a labor market report
   * @param jobTitle The job title to search for
   * @param location The location to search in
   * @param distance The distance in miles from the location
   * @returns The response from the API
   */
  public async submitLaborMarketReportRequest(
    jobTitle: string,
    location: string | undefined,
    distance: string | number | undefined,
  ): Promise<LaborMarketReportRequestOutput | null> {
    const request: LaborMarketReportRequestInput = {
      jobTitle,
      location: location || '',
      searchRadius: typeof distance === 'number' ? distance : 30, // Ensure we always have a number, default to 30
    };

    return this.persistence.submitLaborMarketReport(request);
  }
}
