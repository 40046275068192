<script setup lang="ts">
import dayjs from 'dayjs';
import { computed } from 'vue';
import type {
  GatedWorkExperience,
  SearchGatedUserProfile,
} from '@/core/sourcing/search/types/search-gated-user-profile.type';
import { getUserProfileSortedGatedWorkExperiences } from '@/core/shared/user-profile/utils';
import { stripHtml } from '@/utils/strings/strip-html.utils';

const props = defineProps<{
  pro: SearchGatedUserProfile;
  isExpanded: boolean;
}>();
const DEFAULT_ITEMS = 1;

const experienceToShow = computed(() =>
  props.isExpanded ? workExperienceList.value.length : DEFAULT_ITEMS,
);

const remainingCount = computed(() => workExperienceList.value.length - DEFAULT_ITEMS);

const highlightedWorkExperience = computed(() => {
  if (!props.pro?._highlight?.workExperience) {
    return [];
  }
  return (
    props.pro._highlight?.workExperience.filter(
      (experience) => !!experience.jobTitle?.value || !!experience.employer?.value,
    ) || []
  );
});

function getHighlightedJobTitle(jobTitle: string): string {
  const highlightedExp = highlightedWorkExperience.value.find(
    (exp) =>
      stripHtml(exp.jobTitle?.value || '') === jobTitle ||
      stripHtml(exp.jobTitle?.snippet || '') === jobTitle,
  );
  return highlightedExp?.jobTitle?.snippet || jobTitle;
}

function getHighlightedEmployer(employer: string): string {
  const highlightedExp = highlightedWorkExperience.value.find(
    (exp) =>
      stripHtml(exp.employer?.value || '') === employer ||
      stripHtml(exp.employer?.snippet || '') === employer,
  );
  return highlightedExp?.employer?.snippet || employer;
}

const workExperienceList = computed(() =>
  getUserProfileSortedGatedWorkExperiences(props.pro.workExperience ?? []).filter(
    (experience) => experience.jobTitle,
  ),
);

function _formatDate(exp: GatedWorkExperience): string {
  const startDate = exp.startDateTsUnix ? dayjs.unix(exp.startDateTsUnix).format('MMMM YYYY') : '';
  const endDate = exp.endDateTsUnix ? dayjs.unix(exp.endDateTsUnix).format('MMMM YYYY') : 'Present';
  return `${startDate} - ${endDate}`;
}
</script>
<template>
  <div>
    <div v-if="workExperienceList.length === 0" class="sub-title" data-test-id="no-experience">
      No Work Experience Added
    </div>
    <template v-else>
      <div class="sub-title">
        Work Experience
        <span v-if="!isExpanded && remainingCount > 0" class="counter-text"
          >{{ remainingCount }} more</span
        >
      </div>
      <div
        v-for="(experience, i) in workExperienceList.slice(0, experienceToShow)"
        :key="i"
        data-test-id="experience"
        class="text-xs leading-[18px]"
        :class="{ 'mb-2.5': i !== workExperienceList.length - 1 }"
      >
        <div
          data-test-id="experience-job-title"
          class="ppc_highlighted_text font-bold text-shade-900"
          v-html="getHighlightedJobTitle(experience.jobTitle || '')"
        ></div>
        <div
          data-test-id="experience-employer-name"
          class="ppc_highlighted_text text-shade-800"
          v-html="getHighlightedEmployer(experience.employer || '')"
        ></div>
        <div v-if="experience.startDateTsUnix" class="text-shade-600">
          {{ _formatDate(experience) }}
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss"></style>
