<script setup lang="ts">
import UnlockedLockSvg from '@/assets/svg/unlocked-lock-20.svg?component';
import ProPreviewStats from '@/components/Search/ProPreviewCard/ProPreviewStats.vue';
import ProApplicantList from '@/components/Search/ProPreviewCard/ProApplicantList.vue';
import WorkExperienceList from '@/components/Search/ProPreviewCard/WorkExperienceList.vue';
import EducationList from '@/components/Search/ProPreviewCard/ProEducationList.vue';
import ProSkillsList from '@/components/Search/ProPreviewCard/ProSkillsList.vue';

import Checkbox from '@/components/Shared/Input/Checkbox/Checkbox.vue';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import type { SearchGatedUserProfile } from '@/core/sourcing/search/types/search-gated-user-profile.type';
import { computed, ref } from 'vue';
import { MessagingService } from '@/core/sourcing/messaging/messaging.service';
import { MessagingSource } from '@/core/sourcing/messaging/types/messaging-source.type';
import {
  ProUnlockResultStatus,
  ProUnlockSource,
} from '@/core/sourcing/pro-unlock/types/pro-unlock-source.type';
import { ViewedCandidateService } from '@/core/sourcing/viewed-candidates/viewed-candidate.service';
import { getProActivityRelativeDateTime, isProRecentlyActive } from '@/core/sourcing/utils/dates';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import { getNameForCandidate } from '@/core/sourcing/search/utils/get-name-for-candidate.util';
import JobApplicantService from '@/core/shared/job-applicant/job-applicant.service';
import { InternalError } from '@/core/shared/errors/internal.error';
import { HttpStatus } from '@/core/shared/errors/enums/http-status.enum';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName } from '@/core/shared/tracking/tracking-actions';
import ProUnlockService from '@/core/sourcing/pro-unlock/pro-unlock.service';
import { stripHtml } from '@/utils/strings/strip-html.utils';
import { maskSensitiveInfo } from '@/utils/strings/mask-sensitive-info.utils';
import { isTextSelected } from '@/utils/dom/text-selection.utils';
import { SearchService } from '@/core/sourcing/search/search.service';
import { useMeStore } from '@/core/shared/me/me.store';
import LockUnlockedSvg from '@/assets/svg/lock-unlocked-20.svg?component';

const props = defineProps<{
  pro: SearchGatedUserProfile;
  selectedProId?: number;
}>();

const emit = defineEmits<{
  (e: 'click'): void;
  (e: 'open:messaging', value: boolean): void;
  (e: 'open:resume'): void;
}>();

const subscriptionService = new SubscriptionService();
const messagingService = new MessagingService();
const viewedCandidateService = new ViewedCandidateService();
const jobApplicantService = new JobApplicantService();
const proUnlockService = new ProUnlockService();
const searchService = new SearchService();
const meStore = useMeStore();

const isUnlocked = computed(() => props.pro.unlocked);

const isUnlockingCandidate = ref(false);

// TODO: Remove isGatedSubscription check in next subscription service refactor
// This check is a legacy from v1/v2 subscription model separation
const isUnlockedIconVisible = computed(
  () => isUnlocked.value && subscriptionService.isGatedSubscription,
);

const viewedOn = computed(
  () => viewedCandidateService.viewedCandidatesRecord?.[Number(props.pro.profileId)],
);

const jobApplicants = computed(() => {
  return jobApplicantService.getJobApplicantsByUserProfile(Number(props.pro.profileId));
});

const isWarm = computed(() => {
  const lastActiveTs = props.pro.lastActiveTs;

  if (!lastActiveTs) {
    return false;
  }

  return isProRecentlyActive(lastActiveTs);
});

const highlightedName = computed(() => {
  const nameFirst = props.pro?._highlight?.nameFirst?.value ?? '';
  const nameLast = props.pro?._highlight?.nameLast?.value ?? '';
  const highlightedName = [nameFirst, nameLast].filter(Boolean).join(' ').trim();

  return highlightedName || getNameForCandidate(props.pro);
});

const highlightedResumeText = computed(() => {
  let resumeText = '';

  resumeText = props.pro?._highlight?.resumeText?.snippet ?? '';

  if (!resumeText) {
    resumeText = stripHtml(props.pro?.resumeText ?? '')
      .replace(/●/g, `<span class="text-3xs">●</span>`)
      .slice(0, 110);
    if (resumeText && !isUnlocked.value) {
      resumeText = maskSensitiveInfo(resumeText);
    }
    return resumeText ? `${resumeText}...` : '';
  }

  let parsedResumeText = resumeText.replace(/●/g, `<span class="text-3xs">●</span>`);

  if (parsedResumeText && !isUnlocked.value) {
    parsedResumeText = maskSensitiveInfo(parsedResumeText);
  }

  return `...${parsedResumeText}...`;
});

const isSelectedForBulkMessaging = computed(() => {
  return messagingService.selectedPPCUserProfiles.some(
    (userProfile) => userProfile.id === Number(props.pro.profileId),
  );
});

const isAVectorMatch = computed<boolean>(() => {
  return !!props.pro.vector_distance && props.pro.vector_distance < 0.5;
});

const formattedAddress = computed(() => {
  const { city, state, postalCode } = props.pro.address || {};
  return [city, state, postalCode].filter(Boolean).join(', ');
});

const handleBulkMessagingSelection = async () => {
  try {
    if (isSelectedForBulkMessaging.value) {
      messagingService.removeProFromSelectedUserProfiles(Number(props.pro.profileId));
      return;
    }

    await messagingService.addProUserProfileToSelectedList({
      profileId: props.pro.profileId,
      nameFirst: props.pro.nameFirst,
      nameLast: props.pro.nameLast,
      unlocked: props.pro.unlocked,
      lastActiveTs: props.pro.lastActiveTs,
      source: MessagingSource.PPC,
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to add candidate to bulk messaging');
  }
};

const isExpanded = ref(false);

/**
 * Handles the click event on the ProPreviewCard.
 * Only toggles the expanded state if the user is not selecting text,
 * allowing users to copy content without triggering the expand/collapse action.
 */
const handlePPCardClick = () => {
  if (!isTextSelected()) {
    isExpanded.value = !isExpanded.value;
  }
};

const contactInfo = computed(() => {
  return [props.pro.phone, props.pro.email, formattedAddress.value]
    .filter(Boolean)
    .join('\u00A0 • \u00A0'); // add more space between the elements
});

const unlockProUserProfile = async () => {
  isUnlockingCandidate.value = true;
  try {
    const userProfileIdToUnlock = Number(props.pro.profileId);
    if (!userProfileIdToUnlock) return;
    await proUnlockService.unlockProUserProfiles([userProfileIdToUnlock], ProUnlockSource.PPP);
    // After unlock, refresh the search results
    searchService.searchCandidates().then((result) => {
      // We dont need to await for the job applicants
      // its only to refresh the list of job applicants on the preview card
      if (meStore.employer?.id) {
        jobApplicantService.getJobApplicantsByUserProfileIds(
          meStore.employer.id,
          result.hits.map((candidate) => Number(candidate.profileId)),
        );
      }
    });
  } catch (error) {
    ErrorService.captureException(error);
    if (
      error instanceof InternalError &&
      error.status === HttpStatus.FORBIDDEN &&
      error.data?.status === ProUnlockResultStatus.ERROR_LIMIT_REACHED
    ) {
      SnackbarService.criticalAlt(
        '<b>Unlock limit reached:</b> Your unlocks will refresh at the start of your next billing cycle.',
      );
    } else {
      SnackbarService.critical('Could not unlock user profile, please try again later.');
    }
  } finally {
    isUnlockingCandidate.value = false;
    trackUnlock();
  }
};

function trackUnlock() {
  TrackingService.instance.trackActionEvent(TrackingActionName.UNLOCK, {
    recently_active: isProRecentlyActive(props.pro.lastActiveTs),
  });
}

const openMessaging = async () => {
  try {
    const userProfileIdToUnlock = props.pro.profileId;
    if (!userProfileIdToUnlock) return;

    messagingService.selectedProUserProfiles = [];
    await messagingService.addProUserProfileToSelectedList({
      profileId: userProfileIdToUnlock.toString(),
      nameFirst: props.pro.nameFirst ?? '',
      nameLast: props.pro.nameLast ?? '',
      unlocked: props.pro.unlocked,
      lastActiveTs: props.pro.lastActiveTs ?? '',
      source: MessagingSource.PPC,
    });
    emit('open:messaging', true);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Could not unlock user profile');
  }
};

const isViewResumeVisible = computed(() => props.pro.unlocked && !!props.pro.resumeUrl);
</script>

<template>
  <div
    :id="`pro-preview-card-${pro.profileId}`"
    :class="{
      '!border-tint-200': isExpanded,
      '-m-0.5 border-2 border-highlight-100': isAVectorMatch,
    }"
    @click="handlePPCardClick"
  >
    <div class="flex justify-between">
      <div class="flex items-center overflow-hidden">
        <!-- Multiselect Checkbox -->
        <Checkbox
          class="bulk-selection-checkbox mr-0.5"
          :class="{ '!ml-0': isSelectedForBulkMessaging }"
          :model-value="isSelectedForBulkMessaging"
          @click.stop="handleBulkMessagingSelection"
        />
        <!-- Unlocked icon -->
        <LockUnlockedSvg v-if="isUnlockedIconVisible" class="ml-1.5 mr-1 text-tint-400" />

        <!-- Candidate Name -->
        <span
          data-test-id="pro-name"
          class="ppc_highlighted_text text-[16px] font-bold leading-5"
          :class="{ 'ml-2': !isUnlockedIconVisible }"
          v-html="highlightedName"
        />

        <!-- Last Active Indicator -->
        <div
          v-if="isWarm"
          data-test-id="pro-is-warm"
          class="ml-2 flex h-6 items-center justify-center rounded-[10px] bg-primary-0 px-2 py-0.5"
        >
          <span class="text-xs font-bold leading-[18px] text-primary-600">
            {{ getProActivityRelativeDateTime(pro.lastActiveTs) }}
          </span>
        </div>
      </div>

      <!-- Candidate Unlocked buttons -->
      <div v-if="isUnlockedIconVisible" data-test-id="pro-unlocked-icon">
        <v-btn
          v-if="isViewResumeVisible"
          :ripple="false"
          class="modal-button-secondary mr-2"
          variant="flat"
          density="compact"
          @click.stop.prevent="emit('open:resume')"
          >View resume</v-btn
        >
        <v-btn
          v-else
          :ripple="false"
          class="modal-button-secondary mr-2 disabled:!border-tint-60 disabled:!bg-white disabled:!text-tint-400"
          variant="flat"
          density="compact"
          disabled
          >No resume</v-btn
        >
        <v-btn
          :ripple="false"
          class="modal-button-secondary"
          variant="flat"
          density="compact"
          @click.stop.prevent="openMessaging"
          >Add to job</v-btn
        >
      </div>
      <!-- Candidate locked button -->
      <div v-else data-test-id="pro-locked-button">
        <v-btn
          :ripple="false"
          class="modal-button-primary"
          variant="flat"
          :prepend-icon="UnlockedLockSvg"
          density="compact"
          :loading="isUnlockingCandidate"
          @click.stop.prevent="unlockProUserProfile"
          >Unlock</v-btn
        >
      </div>
    </div>
    <!-- Candidate Contact Info -->
    <div class="mt-2 text-xs font-normal leading-4.5 text-shade-800">
      {{ contactInfo }}
    </div>
    <!-- Candidate Stats -->
    <ProPreviewStats class="mb-2" :pro="pro" :viewed-on="viewedOn" />
    <!-- Pro Applications -->
    <div v-if="jobApplicants" class="mb-2 text-xs font-normal leading-4.5 text-shade-800">
      <ProApplicantList :job-applicants="jobApplicants" :is-expanded="isExpanded" />
    </div>

    <!-- Candidate Details -->
    <div class="mt-6">
      <!-- Resume Text Snippet -->
      <div v-if="highlightedResumeText" class="mt-3">
        <div class="sub-title">Resume highlight:</div>
        <div
          class="ppc_highlighted_text text-xs font-bold leading-4.5"
          data-test-id="pro-resume-text"
          v-html="highlightedResumeText"
        />
      </div>

      <!-- Work Experience -->
      <div class="mt-3">
        <WorkExperienceList
          :pro="props.pro"
          :is-expanded="isExpanded"
          data-test-id="pro-work-experience"
        />
      </div>

      <!-- Education -->
      <div class="mt-3">
        <EducationList :pro="props.pro" :is-expanded="isExpanded" data-test-id="pro-education" />
      </div>

      <!-- Skills -->
      <div class="mt-3">
        <ProSkillsList :pro="props.pro" :is-expanded="isExpanded" data-test-id="pro-skills" />
      </div>

      <!-- Industries are not indexed yet on Typesense: missing from the search results -->
    </div>
  </div>
</template>
<style scoped lang="postcss">
:deep(.sub-title) {
  @apply mb-2.5 text-xs font-bold text-shade-800;

  .counter-text {
    @apply ml-2 font-normal text-highlight-600;
  }
}

.bulk-selection-checkbox {
  @apply ml-[-26px] inline-block transition-all duration-300;
}
div[id^='pro-preview-card'] {
  @apply cursor-pointer rounded-xl border border-tint-60 bg-tint-0 p-5 transition-colors duration-300;

  &:hover {
    @apply border-tint-200;
    box-shadow:
      0px 1px 2px -1px rgba(13, 10, 44, 0.1),
      0px 12px 16px -4px rgba(13, 10, 44, 0.03),
      0px 4px 6px -2px rgba(13, 10, 44, 0.03);

    .bulk-selection-checkbox {
      @apply ml-0;
    }
  }
}
</style>
