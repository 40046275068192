<script setup lang="ts">
import Editor from '@tinymce/tinymce-vue';
import { computed, onMounted, ref, watch } from 'vue';
import debounce from 'lodash/debounce';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import FormSection from './FormSection.vue';
import GenerateJobDescriptionAnimation from '@/components/Jobs/CopilotActivation/GenerateJobDescriptionAnimation.vue';
import MessageTemplate from '@/components/Shared/Input/Template/MessageTemplate.vue';
import {
  cleanHTMLJobDescription,
  makeMarkdownVersionOfJobDescription,
} from '@/core/jobs/copilot-activation/utils/html-parsing.utils';
import TrackingService from '@/core/shared/tracking/tracking.service';
import {
  TrackingActionName,
  TrackingJobDescriptionParsingError,
  TrackingJobDescriptionParsingErrorSource,
} from '@/core/shared/tracking/tracking-actions';
import { JobDescriptionUtils } from '@/utils/job-description-util/job-description.util';
import { ErrorService } from '@/core/shared/errors/error.service';

type BtnApi = {
  setText: (text: string) => void;
};

const emit = defineEmits<{
  (e: 'init:editor'): void;
}>();

const props = defineProps<{
  disabled: boolean;
}>();

const firstLoad = ref(true);
const prePasteText = ref('');

const isDisabled = computed(() => {
  return props.disabled && !firstLoad.value;
});

const copilotActivationService = new CopilotActivationService();

const tinyMceAPIkey = import.meta.env.VITE_TINY_MCE_API_KEY;

const textValue = ref('');
const enableJobDescriptionGenerationBtn = ref<BtnApi>({ setText: () => {} });
const isFormDirty = ref(false);

const checkJobDescriptionParsingErrorsDebounced = debounce(_checkJobDescriptionParsingErrors, 1000);

const hasTriedToUpdateJob = computed(() => {
  return copilotActivationService.hasTriedToUpdateJob;
});

const showsErrorMessage = computed(() => {
  return (isFormDirty.value || hasTriedToUpdateJob.value) && textValue.value.trim() === '';
});
const isGeneratingJobDescription = computed(() => {
  return copilotActivationService.isGeneratingJobDescription;
});
const localJobDescription = computed(() => {
  return copilotActivationService.localJobDescription;
});

function handleInput(value: string) {
  // convert parsed html into Markdown
  const cleanData = cleanHTMLJobDescription(value);
  const md = makeMarkdownVersionOfJobDescription(cleanData);

  copilotActivationService.description = cleanData;
  copilotActivationService.rawDescription = md;

  checkJobDescriptionParsingErrorsDebounced(
    value,
    cleanData,
    TrackingJobDescriptionParsingErrorSource.EDITOR_DATA_CHANGE_PARSING,
  );
}

async function generateJobDescription(api: BtnApi) {
  enableJobDescriptionGenerationBtn.value = api;

  if (isGeneratingJobDescription.value) {
    copilotActivationService.stopGeneratingJobDescription();
  } else {
    TrackingService.trackAction(TrackingActionName.JOB_DESCRIPTION_GENERATED, {
      project_id: copilotActivationService.selectedProjectId,
      job_id: copilotActivationService.selectedJobId,
    });
    await copilotActivationService.generateJobDescription();
  }
}

async function _checkJobDescriptionParsingErrors(
  originalDescription: string,
  parsedDescription: string,
  source: TrackingJobDescriptionParsingErrorSource,
) {
  try {
    const errors: string[] = [];

    if (JobDescriptionUtils.hasDescriptionBulletPointsInAnotherLine(parsedDescription)) {
      errors.push(TrackingJobDescriptionParsingError.BULLET_POINT_IN_ANOTHER_LINE);
    }

    if (JobDescriptionUtils.hasBoldAsterisksNotBeingFormatted(parsedDescription)) {
      errors.push(TrackingJobDescriptionParsingError.BOLD_ASTERISKS_NOT_BEING_FORMATTED);
    }

    if (JobDescriptionUtils.hasBoldAsterisksInAnotherLine(parsedDescription)) {
      errors.push(TrackingJobDescriptionParsingError.BOLD_ASTERISKS_IN_ANOTHER_LINE);
    }

    if (errors.length > 0) {
      TrackingService.trackAction(TrackingActionName.JOB_DESCRIPTION_PARSING_ERROR, {
        errors,
        originalDescription: originalDescription,
        errorDescription: parsedDescription,
        source,
      });
    }
  } catch (error) {
    ErrorService.captureException(error);
  }
}

function handlePrePasteEvent(content: string) {
  prePasteText.value = content;
}

async function handlePostPasteEvent(htmlContent: string) {
  await _checkJobDescriptionParsingErrors(
    prePasteText.value,
    htmlContent,
    TrackingJobDescriptionParsingErrorSource.EDITOR_PASTE_PARSING,
  );
  prePasteText.value = '';
}

watch(textValue, (newValue) => {
  handleInput(`${newValue}`);

  if (newValue !== localJobDescription.value) {
    isFormDirty.value = true;
  }
});

// Some bug while updating the copilotActivationService.localJobDescription was
// not triggering this watch
watch(
  localJobDescription,
  (newValue) => {
    textValue.value = newValue;
  },
  { deep: true, immediate: true },
);

watch(isGeneratingJobDescription, (newValue) => {
  const shouldDeactivate = !newValue;

  const text = shouldDeactivate ? 'Generate my job description' : 'Stop generating';
  enableJobDescriptionGenerationBtn.value.setText(text);

  if (
    !newValue &&
    copilotActivationService.generatedJobDescriptionDocument?.jobDescription.fullfilled
  ) {
    // Set the generated data into the store
    const description = cleanHTMLJobDescription(
      copilotActivationService.generatedJobDescriptionDocument?.jobDescription
        .generatedJobDescription,
    );

    textValue.value = description;
  }
});

onMounted(() => {
  // The Editor or tinyMCE is not disabled on the first load
  // but the UI does refresh the :disable status after the first load
  // even if its FALSE it will keep the UI disabled
  // little hack to fix this issue
  setTimeout(() => {
    firstLoad.value = false;
  }, 500);
});
</script>

<template>
  <FormSection id="job-description" title="Add job description">
    <div
      :class="{
        generating: isGeneratingJobDescription,
      }"
    >
      <div class="relative">
        <Editor
          v-model="textValue"
          :api-key="tinyMceAPIkey"
          output-format="html"
          id="job-editor"
          model-events="change keyup undo redo paste"
          :disabled="isDisabled"
          :init="{
            branding: false,
            elementpath: false,
            height: 500,
            menubar: false,
            contextmenu: ['copy', 'paste', 'link'],
            plugins: 'advlist powerpaste autolink lists link anchor',
            textpattern_patterns: [
              { start: '*', end: '*', format: 'italic' },
              { start: '**', end: '**', format: 'bold' },
              { start: '#', format: 'h1' },
              { start: '##', format: 'h2' },
              { start: '###', format: 'h3' },
              { start: '####', format: 'h4' },
              { start: '#####', format: 'h5' },
              { start: '######', format: 'h6' },
              { start: '---', replacement: '<hr/>' },
              // The following text patterns require the `lists` plugin
              { start: '1. ', cmd: 'InsertOrderedList' },
              { start: '* ', cmd: 'InsertUnorderedList' },
              { start: '- ', cmd: 'InsertUnorderedList' },
            ],
            toolbar: [
              { name: 'fontWeight', items: ['bold', 'italic'] },
              { name: 'itemLists', items: ['bullist', 'numlist'] },
              { name: 'copilotItems', items: ['copilotBtn'] },
            ],
            advlist_bullet_styles: 'disc',
            advlist_number_styles: 'default',
            setup(editor: any) {
              editor.ui.registry.addIcon(
                'copilotIcon',
                ' <svg width=&quot;17&quot; height=&quot;20&quot; viewBox=&quot;0 0 17 20&quot; fill=&quot;none&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot; ><path fill-rule=&quot;evenodd&quot; clip-rule=&quot;evenodd&quot; d=&quot;M5 2L5.67175 3.82825L7.5 4.5L5.67175 5.17175L5 7L4.32825 5.17175L2.5 4.5L4.32825 3.82825L5 2ZM12 13L12.6718 14.8282L14.5 15.5L12.6718 16.1718L12 18L11.3282 16.1718L9.5 15.5L11.3282 14.8282L12 13ZM11.6506 3.27892C11.6132 3.18674 11.5443 3.10953 11.4553 3.05988V3.05901C11.3666 3.00949 11.2631 2.99031 11.1616 3.00461C11.0601 3.01891 10.9666 3.06583 10.8963 3.13775L3.62381 10.5748C3.56291 10.637 3.52226 10.715 3.50688 10.7991C3.49149 10.8833 3.50205 10.9699 3.53724 11.0484C3.57243 11.1268 3.63072 11.1936 3.70491 11.2405C3.77909 11.2875 3.86594 11.3124 3.95471 11.3124H6.97551L5.3383 16.4335C5.308 16.528 5.31184 16.6295 5.3492 16.7216C5.38655 16.8137 5.45521 16.8909 5.54399 16.9406C5.63277 16.9904 5.73643 17.0097 5.83807 16.9954C5.93972 16.9812 6.03336 16.9342 6.10372 16.8622L13.3762 9.42515C13.4371 9.36298 13.4777 9.285 13.4931 9.20085C13.5085 9.1167 13.498 9.03006 13.4628 8.95162C13.4276 8.87317 13.3693 8.80637 13.2951 8.75944C13.2209 8.71252 13.1341 8.68753 13.0453 8.68756H10.0245L11.6617 3.56735C11.692 3.47277 11.6881 3.37109 11.6506 3.27892Z&quot; fill=&quot;black&quot;/></svg> ',
              );
              editor.ui.registry.addButton('copilotBtn', {
                icon: 'copilotIcon',
                text: 'Generate my job description',
                title: 'Generate my job description',
                onAction: (api: BtnApi) => {
                  generateJobDescription(api);
                },
              });
              editor.on('PastePreProcess', (e: any) => {
                handlePrePasteEvent(e.content);
              });
              editor.on('PastePostProcess', async (e: any) => {
                const node = e.node;
                await handlePostPasteEvent(node.outerHTML);
              });
            },
          }"
          @init="emit('init:editor')"
        />
        <GenerateJobDescriptionAnimation />
      </div>
      <div v-if="showsErrorMessage" class="v-field--error my-5 ml-4">
        <MessageTemplate message="Job description is required." class="text-[#B00020]" />
      </div>
    </div>
  </FormSection>
</template>

<style lang="scss" scoped>
:deep(.tox.tox-tinymce) {
  @apply rounded-md border border-tint-80;

  .tox-editor-header {
    box-shadow: none;
    @apply border-b border-solid border-[#E0E0E0];
  }
  .tox-statusbar {
    @apply border-transparent;
  }

  .tox-tbtn__select-label {
    @apply font-semibold;
  }

  .tox-toolbar__group[title='fontWeight'] {
    border-right: solid 1px #e0e0e0 !important;
  }

  .tox-toolbar__group[title='copilotItems'] {
    @apply ml-auto;
    button {
      @apply cursor-pointer rounded-md bg-tint-0 font-sans text-sm leading-4 text-shade-900;
      width: 235px !important;
      height: 32px !important;
      margin-top: 2px !important;
      border: 1px solid !important;
    }
    button > * {
      @apply cursor-pointer;
    }
    .tox-tbtn__select-label {
      display: inline-block;
      margin-left: 6px;
    }
  }
}

.generating :deep(.tox.tox-tinymce) {
  .tox-toolbar__group[title='copilotItems'] {
    button {
      @apply border border-tint-80 bg-gradient-to-r from-transparent to-transparent text-shade-900;
    }
  }
}

:deep(.tox-tinymce--disabled) {
  @apply border-[1px] border-solid border-tint-80 bg-tint-40 opacity-85;

  .tox-editor-header,
  .tox-toolbar-overlord,
  .tox-toolbar__primary {
    @apply pointer-events-none;
    background-color: transparent !important;
  }
  .tox-toolbar__group[title='copilotItems'] {
    button {
      display: none;
    }
  }

  iframe,
  .tox-statusbar {
    background-color: transparent !important;
  }
}
</style>
