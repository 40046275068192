<script setup lang="ts">
import { computed, ref } from 'vue';
import { type Project, ProjectStatus } from '@factoryfixinc/ats-interfaces';
import { useRouter } from 'vue-router';

import MoreVerticalDots from '@/assets/svg/dots-vertical-20.svg?component';

import LightningPurpleIcon from '@/assets/svg/jobs/copilot-lightning-purple.svg';
import LightningBlackIcon from '@/assets/svg/jobs/copilot-lightning-black.svg';

import Duplicate from '@/assets/svg/sourcing/duplicate.svg';
import Edit from '@/assets/svg/edit-black.svg';
import Archive from '@/assets/svg/archive.svg';
import TrendyArrowUp from '@/assets/svg/sourcing/trendy-arrow-up.svg';
import DeleteRed from '@/assets/svg/sourcing/delete-red.svg';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName, TrackingCopilotSource } from '@/core/shared/tracking/tracking-actions';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectService from '@/core/shared/project/project.service';

const TRACKING_SOURCE = TrackingCopilotSource.ACTION_MENU;

const props = defineProps<{
  modelValue: boolean;
  project: Project;
  isLoadingCopilot: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', payload: boolean): void;
  (e: 'toggleCopilot', checked: boolean, isActionMenu: boolean): void;
}>();

const router = useRouter();

const projectService = new ProjectService();
const isOpen = computed<boolean>(() => {
  return props.modelValue;
});
const projectStatus = computed(() => props.project.status);
const isCopilotActivated = computed(() => props.project.copilot);
const isArchived = computed(() => projectStatus.value === ProjectStatus.ARCHIVED);
const isLive = computed(() => projectStatus.value === ProjectStatus.LIVE);
const isLoading = ref<boolean>(false);

function handleUpdateModelValue(value: boolean) {
  emit('update:modelValue', value);
}

async function handleArchiveProject() {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    const isCopilotActivated = props.project.copilot;
    await projectService.archiveProject(props.project.id, isCopilotActivated);
    await projectService.getProjectById(props.project.id);
    handleUpdateModelValue(false);
    TrackingService.trackAction(TrackingActionName.JOB_ARCHIVED, {
      source: TRACKING_SOURCE,
      copilot_status: copilotTrackingStatus.value,
      project_id: props.project.id,
      job_id: props.project.jobId,
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to archive job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function handleUnarchiveProject() {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    await projectService.activateProject(props.project.id);
    await projectService.getProjectById(props.project.id);
    handleUpdateModelValue(false);
    TrackingService.trackAction(TrackingActionName.JOB_UNARCHIVED, {
      source: TRACKING_SOURCE,
      project_id: props.project.id,
      job_id: props.project.jobId,
    });
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to unarchive job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function handleDeleteProject() {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    await projectService.deleteProject(props.project.id);
    handleUpdateModelValue(false);
    TrackingService.trackAction(TrackingActionName.JOB_DELETED, {
      source: TRACKING_SOURCE,
      project_id: props.project.id,
      job_id: props.project.jobId,
    });
    await router.push('/conversations');
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to delete job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function handleDeactivateCopilot() {
  emit('toggleCopilot', isCopilotActivated.value, true);
  handleUpdateModelValue(false);
}

async function handelActivateCopilot() {
  // Activation will be handled in the ProjectCard component
  emit('toggleCopilot', isCopilotActivated.value, true);
}

async function handleEditJob() {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
      source: TrackingCopilotSource.EDIT_JOB,
      copilot_status: copilotTrackingStatus.value,
      project_id: props.project.id,
      job_id: props.project.jobId,
    });

    await router.push(`/jobs/${props.project.id}/copilot-activation`);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to edit job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

async function cloneJob() {
  if (isLoading.value) return;
  try {
    isLoading.value = true;
    const duplicatedProject = await projectService.duplicateProject(props.project.id, {
      title: props.project.title,
      location: props.project.location,
    });
    handleUpdateModelValue(false);
    TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
      source: TrackingCopilotSource.DUPLICATE_JOB,
      project_id: duplicatedProject.id,
      job_id: duplicatedProject.jobId,
    });

    await router.push(`/jobs/${duplicatedProject.id}/copilot-activation`);
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to duplicate job. Please try again later.');
  } finally {
    isLoading.value = false;
  }
}

const isDisabled = computed(() => isLoading.value || props.isLoadingCopilot);

const copilotTrackingStatus = computed(() => {
  return isCopilotActivated.value ? 'activated' : 'deactivated';
});
</script>
<template>
  <span>
    <v-menu
      :model-value="isOpen"
      location="bottom"
      transition="slide-y-transition"
      :close-on-content-click="false"
      @update:model-value="handleUpdateModelValue"
    >
      <template #activator="{ props }">
        <div v-bind="props" class="flex cursor-pointer items-center justify-center rounded-md">
          <MoreVerticalDots
            height="20"
            width="20"
            :class="isOpen ? 'text-tint-60' : 'text-tint-400'"
            class="mt-[-1px] transition-colors hover:!text-tint-60"
          />
        </div>
      </template>

      <div
        class="mt-0.5 rounded-md bg-white py-2 shadow-menu"
        :class="{ 'w-[205px]': !isCopilotActivated, 'w-[235px]': isCopilotActivated }"
      >
        <!-- Live -->
        <template v-if="isLive">
          <!-- Enable Copilot -->
          <button
            v-if="!isCopilotActivated"
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="handelActivateCopilot"
          >
            <img
              class="ml-0.5 mr-0.5"
              :src="LightningPurpleIcon"
              alt="Copilot"
              width="16"
              height="20"
            />
            <p class="mb-0 ml-2 text-xs font-normal">Activate Copilot - Post job</p>
          </button>

          <!-- Disable Copilot -->
          <button
            v-if="isCopilotActivated"
            class="mb-2 flex h-8 w-full cursor-pointer items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="handleDeactivateCopilot"
          >
            <img
              class="ml-0.5 mr-0.5"
              :src="LightningBlackIcon"
              alt="Copilot"
              width="16"
              height="20"
            />
            <p class="mb-0 ml-2 text-xs font-normal">Deactivate Copilot - Remove job</p>
          </button>

          <!-- Edit -->
          <button
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="handleEditJob"
          >
            <img :src="Edit" alt="Edit" width="20" height="20" class="stroke-black" />
            <p class="mb-0 ml-2 text-xs font-normal">Edit job</p>
          </button>

          <!-- Duplicate -->
          <button
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="cloneJob"
          >
            <img :src="Duplicate" alt="Duplicate" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Duplicate and edit job</p>
          </button>

          <!-- Archive -->
          <button
            class="flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="handleArchiveProject"
          >
            <img :src="Archive" alt="Archive" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Archive</p>
          </button>
        </template>

        <!-- Archived -->
        <template v-if="isArchived">
          <!-- Unarchive -->
          <button
            class="mb-2 flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="handleUnarchiveProject"
          >
            <img :src="TrendyArrowUp" alt="Trendy Arrow Up" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Unarchive job</p>
          </button>

          <!-- Delete -->
          <button
            class="flex h-8 w-full items-center justify-start px-3 py-1 hover:bg-tint-40 disabled:opacity-60"
            :disabled="isDisabled"
            @click="handleDeleteProject"
          >
            <img :src="DeleteRed" alt="Delete" width="20" height="20" />
            <p class="mb-0 ml-2 text-xs font-normal">Delete job</p>
          </button>
        </template>
      </div>
    </v-menu>
  </span>
</template>
<style scoped lang="postcss">
/* This style indicates to the user that the action is loading and prevents further clicks */
:disabled:hover {
  @apply animate-linear-loading bg-gradient-to-r from-tint-80 via-tint-0 to-tint-80 bg-[length:200%_auto];
  @apply cursor-wait;
}
</style>
