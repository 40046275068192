<script setup lang="ts">
import PlusSvg from '@/assets/svg/plus-16.svg?component';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName, TrackingCopilotSource } from '@/core/shared/tracking/tracking-actions';
import ProjectService from '@/core/shared/project/project.service';
import ProjectSearchFilters from '@/components/Shared/Projects/ProjectSearchFilters.vue';
import { PageSource } from '@/core/shared/tracking/tracking.type';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import ProjectRemovedFromDeeplink from '@/components/Shared/Projects/ProjectRemovedFromDeeplink.vue';
import ProjectDescriptionOverlay from '@/components/Shared/Projects/ProjectDescriptionOverlay.vue';
import ProjectList from '@/components/Shared/Projects/ProjectList.vue';
import MeService from '@/core/shared/me/me.service';
import { DialogService } from '@/core/shared/dialog/dialog.service';
import { ProjectFilterTab } from '@/core/shared/project/project.type';

const route = useRoute();
const router = useRouter();
const dialogService = new DialogService();
const projectService = new ProjectService();
const meService = new MeService();

const isCreatingJob = ref<boolean>(false);

const hasProjects = computed(() => {
  return projectService.projects.length > 0;
});

const pageSource = computed<PageSource>(() => {
  if (typeof route.name !== 'string') {
    return PageSource.Conversations;
  }

  const lowerCaseRouteName = route.name.toLowerCase();
  const includesSourcing = lowerCaseRouteName.includes('sourcing');

  if (includesSourcing) {
    return PageSource.Sourcing;
  }

  return PageSource.Conversations;
});

async function createJobProject() {
  try {
    isCreatingJob.value = true;
    if (pageSource.value === PageSource.Sourcing) {
      TrackingService.trackAction(TrackingActionName.NEW_SEARCH_STARTED, {
        source: TrackingCopilotSource.PLUS_NEW_SEARCH_BUTTON,
      });
      dialogService.isCreateProjectDialogOpen = true;
    } else {
      await createNewProjectAndJob();
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to create job. Please try again later.');
  } finally {
    isCreatingJob.value = false;
  }
}

async function handleFiltersChange() {
  try {
    // If My Jobs tab is selected, ensure the current user is in the team filter
    const isMyJobsSelected = projectService.selectedTab === ProjectFilterTab.MY_JOBS;
    const hasNoTeamMembers = !projectService.teamIds?.length;
    if (isMyJobsSelected && hasNoTeamMembers) {
      const currentUserId = meService.userProfile?.id;
      if (currentUserId) {
        projectService.teamIds = [currentUserId];
      }
    }

    await projectService.searchProjects({
      skipOnSameSearch: true,
      skipTracking: false,
      pageSource: pageSource.value,
    });

    await projectService.searchAvailableProjects();
    await handleProjectsInQueryParams();
    await handleNoSelectedProjectState();
    projectService.didLoadProjects = true;
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load jobs');
  }
}

async function handleProjectsInQueryParams() {
  const query = route.query;
  let projects: number[] = [];

  if (typeof query.projects === 'string') {
    projects = [Number(query.projects)];
  }

  if (Array.isArray(query.projects)) {
    projects = query.projects.map(Number);
  }

  const validProjectIds = projects.filter((id) => !isNaN(id));

  if (validProjectIds.length === 0) {
    return;
  }

  const firstValidProjectId = validProjectIds[0];
  await handleProjectChange(firstValidProjectId);
}

async function handleNoSelectedProjectState() {
  if (hasProjects.value && !projectService.currentProject?.id) {
    await handleProjectChange(projectService.projects[0].id);
  }
}

async function handleProjectChange(projectId: number) {
  await projectService.getProjectById(projectId);

  if (pageSource.value === PageSource.Sourcing) {
    await router.push({
      name: 'sourcing-job-id',
      params: { id: `${projectId}` },
    });
  }
}

const companyLocation = computed(() => {
  let location = '';
  location += meService.employer?.city ? `${meService.employer?.city}, ` : '';
  location += meService.employer?.state ? `${meService.employer?.state}, ` : '';
  location += `USA`;
  return location;
});

const newButtonText = computed(() => {
  return pageSource.value === PageSource.Sourcing ? 'New search' : 'New job';
});

async function createNewProjectAndJob() {
  const project = await projectService.createProject({
    title: 'No title',
    location: companyLocation.value,
    activeOutreachEnabled: true,
  });

  TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_STARTED, {
    source: TrackingCopilotSource.PLUS_NEW_JOB_BUTTON,
    project_id: project.id,
    job_id: project.jobId,
  });

  // Lets give enough time to the Project side effect to create the new Job
  setTimeout(async () => {
    isCreatingJob.value = false;
    await router.push(`/jobs/${project.id}/copilot-activation`);
  }, 500);
}
</script>
<template>
  <!-- New Job Button -->
  <div class="flex items-center justify-between px-4 pt-[18px]">
    <div class="font-serif text-base font-black leading-6">Saved jobs</div>
    <v-btn
      :ripple="false"
      :disabled="isCreatingJob"
      :loading="isCreatingJob"
      variant="flat"
      class="add-job-button"
      @click="createJobProject"
    >
      <PlusSvg class="mr-1" />
      {{ newButtonText }}
    </v-btn>
  </div>
  <!-- Project Search Filters -->
  <div class="mx-4 mb-4 mt-6">
    <ProjectSearchFilters @change:filters="handleFiltersChange" />
  </div>

  <!-- Project List -->
  <div
    v-if="!projectService.isProjectFromDeepLinkRemoved"
    class="jobs-drawer-scrollbar mt-2 h-[calc(100vh-212px)] overflow-y-auto px-4"
  >
    <ProjectList
      :project-id="projectService.currentProject?.id"
      @change:project-id="handleProjectChange"
      @add-job="createJobProject"
    />
  </div>

  <div
    v-if="projectService.isProjectFromDeepLinkRemoved && !projectService.isSearchingProjects"
    class="px-1 pb-2"
  >
    <ProjectRemovedFromDeeplink />
  </div>

  <ProjectDescriptionOverlay />
</template>

<style scoped>
/** Scrollbar width */
.jobs-drawer-scrollbar::-webkit-scrollbar {
  width: 5px;
}

/** Scrollbar color and border radius */
.jobs-drawer-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-shade-600;
  border-radius: 10px;
}
.add-job-button {
  @apply !h-auto bg-highlight-500 !px-2 !py-1.5 !text-xs !font-bold;
  @apply !normal-case leading-[18px] !tracking-normal text-white;
  @apply !transition-colors !duration-300;
}
</style>
