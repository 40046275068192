import {
  findTagsBrokenIntoMultipleLinesWithOpeningAndClosingSeparators,
  findTagsWithSpecifiedContentOnHTMLString,
  fixTagsBrokenIntoMultipleLinesWithOpeningAndClosingSeparators,
  removeTagsWithSpecifiedContentOnHTMLString,
  replaceTagsWithSpecifiedContentOnHTMLStringWithSpecifiedContent,
} from '@/core/jobs/copilot-activation/utils/html-parsing.utils';

export class JobDescriptionUtils {
  /**
   * Checks if the job description contains bullet points that are separated from their text.
   *
   * Ex.
   * <p>•</p><p>The item text</p> OR <p>-</p><p>The item text</p> OR <p>*</p><p>The item text</p>
   *
   * @param {string} description - The job description to check
   * @returns {boolean} True if the job description contains bullet points separated from their text in another line, false otherwise
   */
  static hasDescriptionBulletPointsInAnotherLine(description: string): boolean {
    if (!description?.trim()) {
      return false;
    }

    const malformedTags = findTagsWithSpecifiedContentOnHTMLString(
      description,
      'P',
      /^(\s)*[•*-](\s)*$/,
    );
    const hasInstancesOfBulletPoints = malformedTags.length !== 0;

    return hasInstancesOfBulletPoints;
  }

  /**
   * Fixes bullet points that are separated from their text.
   *
   * Ex.
   * <p>•</p><p>The item text</p> => <p>• The item text</p>
   *
   * @param {string} description - The job description to fix
   * @returns {string} The fixed job description
   */
  static fixBulletPointsInAnotherLine(description: string): string {
    if (!description?.trim()) {
      return description;
    }

    const openingSeparator = /^(\s)*[•*-](\s)*$/;
    const closingSeparator = /(.)*/;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openingFixFunction = (_content: string) => {
      return '||BULLET_POINT_OPENING_TAGGED_FOR_REMOVAL||';
    };
    const closingFixFunction = (content: string) => {
      return '• ' + content;
    };

    let fixedDescription = fixTagsBrokenIntoMultipleLinesWithOpeningAndClosingSeparators(
      description,
      'P',
      openingSeparator,
      closingSeparator,
      openingFixFunction,
      closingFixFunction,
    );

    // Remove the bullet point opening tag
    fixedDescription = removeTagsWithSpecifiedContentOnHTMLString(
      fixedDescription,
      'P',
      /\|\|BULLET_POINT_OPENING_TAGGED_FOR_REMOVAL\|\|/g,
    );

    return fixedDescription;
  }

  /**
   * Checks if the job description contains bold asterisks that are not being formatted.
   *
   * Ex.
   * <p>**The item text**</p>
   *
   * @param {string} description - The job description to check
   * @returns {boolean} True if the job description contains bold asterisks not being formatted, false otherwise
   */
  static hasBoldAsterisksNotBeingFormatted(description: string): boolean {
    if (!description?.trim()) {
      return false;
    }

    /**
     * This regex matches one or more occurrences of bold text patterns like "**text**"
     *
     *   \*\*  Matches the literal '**' (opening marker)
     *   (.*?) Non-greedy match of any characters (captures the inner text)
     *   \*\*  Matches the literal '**' (closing marker)
     *
     *   (...)+  Ensures that the entire bold pattern can repeat one or more times
     *  so that it can match multiple occurrences of bold text in the description like
     *  **text** **text**
     */
    const boldAsterisksRegex = /(\*\*(.*?)\*\*)+/;

    const malformedTags = findTagsWithSpecifiedContentOnHTMLString(
      description,
      'P',
      boldAsterisksRegex,
    );

    return malformedTags.length !== 0;
  }

  /**
   * Checks if the job description contains bold asterisks that are separated from their text.
   *
   * Ex.
   * <p>**The item text</p><p>**</p>
   *
   * @param {string} description - The job description to check
   * @returns {boolean} True if the job description contains bold asterisks separated from their text in another line, false otherwise
   */
  static hasBoldAsterisksInAnotherLine(description: string): boolean {
    if (!description?.trim()) {
      return false;
    }
    const openingSeparator = /\s*(\*\*)/;
    const closingSeparator = /\s*(\*\*)/;
    const malformedTags = findTagsBrokenIntoMultipleLinesWithOpeningAndClosingSeparators(
      description,
      'P',
      openingSeparator,
      closingSeparator,
    );

    return malformedTags.length !== 0;
  }

  /**
   * Fixes bold asterisks that are not being formatted.
   *
   * Ex.
   * <p>**The item text**</p> => <p><b>The item text</b></p>
   *
   * @param {string} description - The job description to fix
   * @returns {string} The fixed job description
   */
  static fixBoldAsterisksNotBeingFormatted(description: string): string {
    if (!description?.trim()) {
      return description;
    }

    const boldAsterisksRegex = /(\*\*(.*?)\*\*)+/;
    const fixFunction = (content: string) => {
      // Replace all occurrences of **text** with <b>text</b>
      return content.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    };

    const fixedDescription = replaceTagsWithSpecifiedContentOnHTMLStringWithSpecifiedContent(
      description,
      'P',
      boldAsterisksRegex,
      fixFunction,
    );

    return fixedDescription;
  }

  /**
   * Fixes bold asterisks that are separated from their text.
   *
   * Ex.
   * <p>**The item text</p><p>**</p> => <p><b>The item text</b></p>
   *
   * @param {string} description - The job description to fix
   * @returns {string} The fixed job description
   */
  static fixBoldAsterisksInAnotherLine(description: string): string {
    if (!description?.trim()) {
      return description;
    }

    const openingSeparator = /(\*\*)/;
    const closingSeparator = /\s*(\*\*)/;
    const openingFixFunction = (content: string) => {
      let fixedContent = content;
      // Add a opening <b> tag
      fixedContent = fixedContent.replace(openingSeparator, '<b>');
      // Close the <b> tag to bold the entire content after the opening separator
      return fixedContent + '</b>';
    };
    const closingFixFunction = (content: string) => {
      let fixedContent = content;
      // Add a closing <b> tag
      fixedContent = fixedContent.replace(closingSeparator, '</b>');
      // Open the <b> tag to bold the entire content before the closing separator
      return '<b>' + fixedContent;
    };

    const fixedDescription = fixTagsBrokenIntoMultipleLinesWithOpeningAndClosingSeparators(
      description,
      'P',
      openingSeparator,
      closingSeparator,
      openingFixFunction,
      closingFixFunction,
    );

    return fixedDescription;
  }
}
